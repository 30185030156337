export const doorTypesSort: number[] = [
  1, // Rączka
  25, // Tor
  19, // Tor górny
  22, // Tor dolny
  28, // Maskownica
  26, // Tor pojedynczy
  7, // Listwa pozioma górna
  10, // Listwa pozioma dolna
  14, // Wkładka do listwy dolnej
  16, // Kątownik
  34, // Łącznik
  37, // Teownik
  40, // Płaskownik
  49, // Wózek
  43, // Wózek górny
  46, // Wózek dolny
  136, // Zestaw
  67, // Uszczelka
  64, // Wkręt
  52, // Domyk
  112, // Akcesoria dla Domyku
  61, // Szczotka
  58, // Szczotka odbojowa 
  55, // Szczotka przeciwkurzowa
  133, // Akcesoria 
  151, // Narzędzie  
]

export const kreatorTypesSort: number[] = [
  31, // Profil
  77, // Nóżka
  61, // Szczotka
  70, // Zawias
  64, // Wkręt
  67, // Uszczelka
  151, // Narzędzie
]

export const productsSort: string[] = [
  '5a225295-0091-0000-a4b1-30ecd6d90d59', // ZŁĄCZE MEBLOWE Z-02 BIAŁY (80 SZT.)
  '1c5979ed-0091-0000-a3e3-7a8acc1ba39e', // ZŁĄCZE MEBLOWE Z-02 CALVADOS/GRUSZA POLNA (80 SZT.)
  '7ecf57a4-0091-0000-be87-f9948bd8c0a1', // ZŁĄCZE MEBLOWE Z-02 CZARNY (80 SZT.)
  '37236a88-0091-0000-8c61-a81ed86e9933', // ZŁĄCZE MEBLOWE Z-02 KLON/BUK (80 SZT.)
  '462dc5b5-0091-0000-92ac-a9c2c5c52df2', // ZŁĄCZE MEBLOWE Z-02 OLCHA (80 SZT.)
  '7a4e89fd-0091-0000-847c-9fccb8799813', // ZŁĄCZE MEBLOWE Z-02 ORZECH (80 SZT.)
  '45e4f1ca-0091-0000-89e6-8d115015138f', // ZŁĄCZE MEBLOWE Z-02 POPIEL/SZARY (80 SZT.)
  '400f79f1-0091-0000-a629-9460f29afda1', // ZŁĄCZE MEBLOWE Z-02 RUSTIKAL (80 SZT.)
  '231cc9ae-0091-0000-9428-5f1d6c68a079', // ZŁĄCZE MEBLOWE Z-02 WENGE (80 SZT.)
  '0f102e68-0091-0000-9f0f-1a56994c5059', // ZŁĄCZE MEBLOWE Z-02 WIŚNIA (80 SZT.)
  '0ca7ded7-0091-0000-8893-446a3e5c7710', // ZŁĄCZE MEBLOWE Z-02 KĄTOWNIK BEZ OSŁONY (80 SZT.)

  '625298a6-0091-0000-b879-f4a35a3e5cbd', // ZŁĄCZE MEBLOWE Z-02 PLUS BIAŁY BLISTER (12 SZT.)
  '7f3d79dd-0091-0000-ae53-20f3da23d47a', // ZŁĄCZE MEBLOWE Z-02 PLUS CALVADOS/GRUSZA POLNA BLISTER (12 SZT.)
  '5916861d-0091-0000-909b-f4424c8eae38', // ZŁĄCZE MEBLOWE Z-02 PLUS CZARNY BLISTER (12 SZT.)
  '0656eebd-0091-0000-be50-5807c4f1daa5', // ZŁĄCZE MEBLOWE Z-02 PLUS KLON/BUK BLISTER (12 SZT.)
  '2036ffa1-0091-0000-bc98-e126f190d7fd', // ZŁĄCZE MEBLOWE Z-02 PLUS OLCHA BLISTER (12 SZT.)
  '5b2f2c77-0091-0000-9b08-7976da7743b9', // ZŁĄCZE MEBLOWE Z-02 PLUS ORZECH BLISTER (12 SZT.)
  '0469e8b6-0091-0000-9608-87a182630fd7', // ZŁĄCZE MEBLOWE Z-02 PLUS POPIEL/SZARY BLISTER (12 SZT.)
  '1c2b4094-0091-0000-bd56-bcab7da94b42', // ZŁĄCZE MEBLOWE Z-02 PLUS RUSTIKAL BLISTER (12 SZT.)
  '383dcbc8-0091-0000-8b0e-031b9b0eeaa9', // ZŁĄCZE MEBLOWE Z-02 PLUS WENGE BLISTER (12 SZT.)
  '423c736b-0091-0000-9408-0da2426f5fe1', // ZŁĄCZE MEBLOWE Z-02 PLUS WIŚNIA BLISTER (12 SZT.)

  '16d00a7c-0091-0000-a770-ccd72eb633ec', // ZŁĄCZE MEBLOWE Z-02 MAX BIAŁY BLISTER (8 SZT.)
  '4d1ca856-0091-0000-8046-67cd4b5a5a4a', // ZŁĄCZE MEBLOWE Z-02 MAX CALVADOS/GRUSZA POLNA BLISTER (8 SZT.)
  '14d22fa0-0091-0000-a3ca-acd384e23ba3', // ZŁĄCZE MEBLOWE Z-02 MAX CZARNY BLISTER (8 SZT.)
  '78b0fe46-0091-0000-b7e4-3bb05c46398f', // ZŁĄCZE MEBLOWE Z-02 MAX KLON/BUK BLISTER (8 SZT.)
  '6c26e055-0091-0000-a304-dac76be5c255', // ZŁĄCZE MEBLOWE Z-02 MAX OLCHA BLISTER (8 SZT.)
  '05270d29-0091-0000-9369-d8f1cfc4b123', // ZŁĄCZE MEBLOWE Z-02 MAX ORZECH BLISTER (8 SZT.)
  '035d40d6-0091-0000-9701-49a432d45cdf', // ZŁĄCZE MEBLOWE Z-02 MAX POPIEL/SZARY BLISTER (8 SZT.)
  '6486755c-0091-0000-a247-267263ed1ef0', // ZŁĄCZE MEBLOWE Z-02 MAX RUSTIKAL BLISTER (8 SZT.)
  '035b7307-0091-0000-bbca-9df8582e2e76', // ZŁĄCZE MEBLOWE Z-02 MAX WENGE BLISTER (8 SZT.)
  '4e3e3adb-0091-0000-be01-1ba5990235f3', // ZŁĄCZE MEBLOWE Z-02 MAX WIŚNIA BLISTER (8 SZT.)

  '0ff7e186-0091-0000-8ffb-99783238ff12', // ZŁĄCZE MEBLOWE Z-03 BIAŁY (100 SZT.)
  '3790238c-0091-0000-85ad-dd6d32726b41', // ZŁĄCZE MEBLOWE Z-03 CALVADOS/GRUSZA POLNA (100 SZT.)
  '2be2c089-0091-0000-87a8-bf7ea20c7d34', // ZŁĄCZE MEBLOWE Z-03 CZARNY (100 SZT.)
  '6b7a1d68-0091-0000-a225-f3921281bafc', // ZŁĄCZE MEBLOWE Z-03 KLON/BUK (100 SZT.)
  '4ed30e74-0091-0000-a3e7-081104fa3bbe', // ZŁĄCZE MEBLOWE Z-03 OLCHA (100 SZT.)
  '7fa83de4-0091-0000-a589-3d5bd4a2f463', // ZŁĄCZE MEBLOWE Z-03 ORZECH (100 SZT.)
  '4494d988-0091-0000-a72a-e80bec959ee3', // ZŁĄCZE MEBLOWE Z-03 POPIEL/SZARY (100 SZT.)
  '0b382457-0091-0000-be1b-4248f54a9dee', // ZŁĄCZE MEBLOWE Z-03 RUSTIKAL (100 SZT.)
  '65aa568e-0091-0000-bfa7-f4a57811c516', // ZŁĄCZE MEBLOWE Z-03 WENGE (100 SZT.)
  '241da8c6-0091-0000-8ae2-4e41aea618c4', // ZŁĄCZE MEBLOWE Z-03 WIŚNIA (100 SZT.)

  '5f91a178-0091-0000-bea4-358cded7db9a', // ZŁĄCZE MEBLOWE Z-02 PLUS BIAŁY (80 SZT.)
  '36b6317d-0091-0000-a030-2f7323c751b1', // ZŁĄCZE MEBLOWE Z-02 PLUS CALVADOS/GRUSZA POLNA (80 SZT.)
  '2c76b118-0091-0000-a23a-c80b5c7e6fc3', // ZŁĄCZE MEBLOWE Z-02 PLUS CZARNY (80 SZT.)
  '523d0c10-0091-0000-ad3e-cc98feb209e4', // ZŁĄCZE MEBLOWE Z-02 PLUS KLON/BUK (80 SZT.)
  '2bfd16d4-0091-0000-86ea-2a7d6bc2560c', // ZŁĄCZE MEBLOWE Z-02 PLUS OLCHA (80 SZT.)
  '27d15319-0091-0000-8ba9-553df9b2ca90', // ZŁĄCZE MEBLOWE Z-02 PLUS ORZECH(80 SZT.)
  '340f4191-0091-0000-ba40-71141eba8a3a', // ZŁĄCZE MEBLOWE Z-02 PLUS POPIEL/SZARY (80 SZT.)
  '199a4c6e-0091-0000-be69-68c3deb2276c', // ZŁĄCZE MEBLOWE Z-02 PLUS RUSTIKAL (80 SZT.)
  '2bc27dcb-0091-0000-90aa-6c2cf555b275', // ZŁĄCZE MEBLOWE Z-02 PLUS WENGE (80 SZT.)
  '56243059-0091-0000-b71f-8d876f26a3c2', // ZŁĄCZE MEBLOWE Z-02 PLUS WIŚNIA (80 SZT.)
  '6da92443-0091-0000-aa8b-6f09884e7c0d', // ZŁĄCZE MEBLOWE Z-02 PLUS KĄTOWNIK BEZ OSŁONKI (80 SZT.)

  '6abd407c-0091-0000-8ad8-7415bd29b749', // ZŁĄCZE MEBLOWE Z-02 MAX BIAŁY (50 SZT.)
  '7ed75555-0091-0000-9407-a1d83866b2cd', // ZŁĄCZE MEBLOWE Z-02 MAX CALVADOS/GRUSZA POLNA (50 SZT.)
  '2716214f-0091-0000-9791-f581e655b8bf', // ZŁĄCZE MEBLOWE Z-02 MAX CZARNY (50 SZT.)
  '5e2c2295-0091-0000-a3f2-ad4455a82490', // ZŁĄCZE MEBLOWE Z-02 MAX KLON/BUK (50 SZT.)
  '7b5f0ccc-0091-0000-89f6-753de27832ee', // ZŁĄCZE MEBLOWE Z-02 MAX OLCHA (50 SZT.)
  '0c1e018f-0091-0000-b76b-95db796442fe', // ZŁĄCZE MEBLOWE Z-02 MAX ORZECH (50 SZT.)
  '4078f3f6-0091-0000-a423-98a09a7c7159', // ZŁĄCZE MEBLOWE Z-02 MAX POPIEL/SZARY (50 SZT.)
  '17aaf92e-0091-0000-bd3f-1b63b37276d5', // ZŁĄCZE MEBLOWE Z-02 MAX RUSTIKAL (50 SZT.)
  '6cfbe634-0091-0000-9c1b-4fefaf8cdfbe', // ZŁĄCZE MEBLOWE Z-02 MAX WENGE (50 SZT.)
  '229f241d-0091-0000-9e40-33ffca214c9b', // ZŁĄCZE MEBLOWE Z-02 MAX WIŚNIA (50 SZT.)
  '64ac87a9-0091-0000-a877-57e6dbaf3496', // ZŁĄCZE MEBLOWE Z-02 MAX KĄTOWNIK BEZ OSŁONY (50 SZT.)
]

import { SitemapResponse } from "types/Menu/SitemapResponse";

export const sitemap: SitemapResponse[] = [
  { link: "/", name: "Systemy drzwiowe", inNavbarVisible: true, args: { "door": "index" } },
  {
    name: "Drzwi przesuwne", inFooterVisible: true, children: [
      { link: "/medium-56-afrodyta", name: "Afrodyta", inFooterVisible: true, args: { "door": "56de4421-0000-0000-999f-429028990c47" } },
      { link: "/medium-56-classic-plus", name: "Classic Plus", inFooterVisible: true, args: { "door": "4566abe4-0000-0000-885a-6a98b6e33e76" } },
      { link: "/medium-56-kronos", name: "Kronos", inFooterVisible: true, args: { "door": "0bc39355-0000-0000-9885-883e735f6cc4" } },
      { link: "/medium-56-uranos", name: "Uranos", inFooterVisible: true, args: { "door": "32494847-0000-0000-9c05-e07d024d3f91" } },
      { link: "/medium-56-zeus", name: "Zeus", inFooterVisible: true, args: { "door": "28853444-0000-0000-aabc-c1f2f6291bf8" } },
      { link: "/premium-75-classic", name: "Classic", inFooterVisible: true, args: { "door": "0082cf72-0000-0000-afcc-ab027933ea92" } },
      { link: "/premium-75-eris", name: "Eris", inFooterVisible: true, args: { "door": "21ca6a62-0000-0000-b91a-e1f1449cfb42" } },
      { link: "/premium-75-juno", name: "Juno", inFooterVisible: true, args: { "door": "6d2e86be-0000-0000-a1aa-da712b8649cb" } },
      { link: "/premium-75-juno-ramowa", name: "Juno ramowa", inFooterVisible: true, args: { "door": "11597024-0000-0000-be91-479613e1f50e" } },
      { link: "/premium-75-merkury", name: "Merkury", inFooterVisible: true, args: { "door": "0823a212-0000-0000-b88f-775b13c77911" } },
      { link: "/premium-75-neptun", name: "Neptun", inFooterVisible: true, args: { "door": "7d7a4275-0000-0000-aa0b-834df4134416" } },
      { link: "/premium-75-orion", name: "Orion", inFooterVisible: true, args: { "door": "5032154e-0000-0000-a87c-631937f59504" } },
      { link: "/premium-75-pluton", name: "Pluton", inFooterVisible: true, args: { "door": "7b531ebd-0000-0000-8a68-bc34d84b2632" } },
      { link: "/premium-75-syriusz", name: "Syriusz", inFooterVisible: true, args: { "door": "0bb45059-0000-0000-830f-cd64c6b9937d" } },
      { link: "/premium-75-uran", name: "Uran", inFooterVisible: true, args: { "door": "76058e3e-0000-0000-9f78-74b8d8ce4d03" } },
      { link: "/premium-75-wega", name: "Wega", inFooterVisible: true, args: { "door": "744d4d27-0000-0000-8920-89671dc5756b" } },
      { link: "/premium-75-wenus", name: "Wenus", inFooterVisible: true, args: { "door": "70292828-0000-0000-be5a-21faab10af9a" } },
      { link: "/premium-75-wenus-eko", name: "Wenus Eko", inFooterVisible: true, args: { "door": "24ab8c10-0000-0000-a935-beffcdb9dc63" } },
      { link: "/premium-75-westa", name: "Westa", inFooterVisible: true, args: { "door": "38fb6d6c-0000-0000-ae83-f34e95f34804" } },
      { link: "/premium-75-westa-bis", name: "Westa Bis", inFooterVisible: true, args: { "door": "79ec2526-0000-0000-96f6-1c603779c32d" } },
      { link: "/premium-75-westa-ramowa", name: "Westa ramowa", inFooterVisible: true, args: { "door": "0c834711-0000-0000-ae91-22bd20002bef" } },
      { link: "/exclusive-82-ares", name: "Ares", inFooterVisible: true, args: { "door": "6e92d83d-0000-0000-83cc-a97064a6cb36" } },
      { link: "/exclusive-82-hermes", name: "Hermes", inFooterVisible: true, args: { "door": "03bcbe96-0000-0000-a877-4a794da685fd" } },
      { link: "/exclusive-82-posejdon", name: "Posejdon", inFooterVisible: true, args: { "door": "3f0713f0-0000-0000-b0fc-01dd94f1c6fa" } },
      { link: "/solo", name: "Solo", inFooterVisible: true, args: { "door": "1257708d-0000-0000-8c59-1394ecf442b4" } },
    ]
  },
  {
    name: "Drzwi składane", inFooterVisible: true, children: [
      { link: "/tytan-ares", name: "Ares", inFooterVisible: true, args: { "door": "29a9ec78-0000-0000-8a32-111cc876c130" } },
      { link: "/tytan-hermes", name: "Hermes", inFooterVisible: true, args: { "door": "31df109d-0000-0000-baf4-92cb7980f0fd" } },
      { link: "/tytan-wega", name: "Wega", inFooterVisible: true, args: { "door": "306f09ba-0000-0000-aa03-69d28383c383" } },
      { link: "/tytan-westa", name: "Westa", inFooterVisible: true, args: { "door": "6b2bcf50-0000-0000-8d45-5a7e7fb343b3" } },
      { link: "/tytan-westa-ramowa", name: "Westa ramowa", inFooterVisible: true, args: { "door": "1ba7d5a6-0000-0000-8670-ab231a400579" } },
    ]
  },
  {
    name: "Drzwi rozwierane", inFooterVisible: true, children: [
      { link: "/cyklop-ares", name: "Ares", inFooterVisible: true, args: { "door": "4fdb09fd-0000-0000-bf00-55e3da4563fb" } },
      { link: "/cyklop-hermes", name: "Hermes", inFooterVisible: true, args: { "door": "023ed924-0000-0000-b90a-70a85ef84b25" } },
      { link: "/cyklop-syriusz", name: "Syriusz", inFooterVisible: true, args: { "door": "7702a270-0000-0000-9165-a0ad9530194c" } },
      { link: "/cyklop-wega", name: "Wega", inFooterVisible: true, args: { "door": "027ed7ce-0000-0000-86c5-3217fef42752" } },
      { link: "/cyklop-westa", name: "Westa", inFooterVisible: true, args: { "door": "1d9e45a9-0000-0000-ae34-e8f68b24de0f" } },
      { link: "/cyklop-westa-ramowa", name: "Westa ramowa", inFooterVisible: true, args: { "door": "47f8af64-0000-0000-b297-a431a49abc77" } },
      { link: "/cyklop-westa-bis", name: "Westa Bis", inFooterVisible: true, args: { "door": "523c2329-0000-0000-9c3e-c691a55e7aeb" } },
    ]
  },
  {
    name: "Drzwi przejściowe", inFooterVisible: true, children: [
      { link: "/tytan-kronos", name: "Kronos", inFooterVisible: true, args: { "door": "3344dfd4-0000-0000-8159-05be9368dc68" } },
      { link: "/tytan-neptun", name: "Neptun", inFooterVisible: true, args: { "door": "4e2b7da7-0000-0000-b098-22abb42afb47" } },
      { link: "/tytan-posejdon", name: "Posejdon", inFooterVisible: true, args: { "door": "10c91129-0000-0000-8975-7d9b00987b02" } },
      { link: "/tytan-uran", name: "Uran", inFooterVisible: true, args: { "door": "74524731-0000-0000-aa83-45db4a6231c3" } },
      { link: "/tytan-uranos", name: "Uranos", inFooterVisible: true, args: { "door": "1518e559-0000-0000-a376-6ec7fa733df7" } },
    ]
  },
  { link: "/system-kreator", name: "System Kreator", inNavbarVisible: true, inFooterVisible: true, args: { "kreator": "70005517-0000-0000-a1eb-6c4f1049addc" } },
  { link: "/akcesoria-meblowe", name: "Akcesoria meblowe", inNavbarVisible: true, inFooterVisible: true, args: { "accessories": "67f6011d-0000-0000-8b53-96f89177fea2" } },
  { link: "/zlacza-meblowe", name: "Złącza meblowe", inNavbarVisible: true, inFooterVisible: true, args: { "connector": "651dcaa6-0000-0000-a4a2-48440197058a" } },
  { link: "/profile-wykonczeniowe", name: "Elementy dodatkowe", inNavbarVisible: true, inFooterVisible: true, args: { "decors": "7463dab5-0000-0000-b2bc-dda60ca9f023" } },
  { link: "/wspolpraca-i-korzysci", name: "Współpraca i Korzyści", inNavbarVisible: true, inFooterVisible: true, args: { "features": "features" } },
  { link: "/o-nas-i-kontakt", name: "O nas i Kontakt", inNavbarVisible: true, inFooterVisible: true, args: { "contact": "contact" } },
  { link: "/polityka-prywatnosci", name: "Polityka prywatności", inFooterVisible: true, args: { "policy": "policy" } },
]

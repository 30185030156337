import { Button } from "components/Form/Button"
import { Input } from "components/Form/Input"
import { Textarea } from "components/Form/Textarea"
import { useForm } from "react-hook-form"
import { sendMessage } from "services/api.message"

import Check from "assets/Circle-check.svg"
import { CheckBox } from "components/Form/CheckBox"
import { NavLink } from "react-router-dom"

export const ContactEditFormComponent = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: {
      errors,
      isSubmitSuccessful
    }
  } = useForm<{
      messageSenderName: string;
      messageSenderContactEmailAddress: string;
      message: string;
      messagePolicy: boolean;
      messageCopy: boolean;
    }
  >({
      defaultValues: {
        messageCopy: true
      }
    }
  )

  const onSubmit = (
    message : {
      messageSenderName: string;
      messageSenderContactEmailAddress: string;
      message: string;
    }
  ) => {
    return sendMessage(message)

    .catch(
      (x) => setError(
        "root", { type: "server", message: x }
      )
    )
  }

  return (
    <>
      <form className="flex flex-col gap-y-6"
        onSubmit={
          handleSubmit(onSubmit)
        }
      >
        <Input type={"text"} label={"Imię"} placeholder={"Wpisz imię"} errorMessage={errors.messageSenderName?.message}
          {...register("messageSenderName", {
                required: "Uzupełnij imię."
              }
            )
          }
        />

        <Input type={"email"} label={"Email"} placeholder={"Wpisz adres e-mail"} errorMessage={errors.messageSenderContactEmailAddress?.message}
          {...register("messageSenderContactEmailAddress", {
                required: "Uzupełnij adres e-mail."
              }
            )
          }
        />

        <Textarea label={"Wiadomość"} placeholder={"Wpisz wiadomość"} errorMessage={errors.message?.message}
          {...register("message", {
                required: "Uzupełnij wiadomość."
              }
            )
          }
        />

        <CheckBox errorMessage={errors.messagePolicy?.message}
          label={(
              <>
                Wyrażam zgodę na przetwarzanie danych osobowych w celu udzielenia odpowiedzi na przesłane zapytanie. <NavLink to="/polityka-prywatnosci" className="underline">Polityka prywatności</NavLink>
              </>
            )
          }
          {...register("messagePolicy", {
                required: "Wyraź zgodę na przetwarzanie danych."
              }
            )
          }
          value={"true"}
        />

        <CheckBox errorMessage={errors.messageCopy?.message}
          label={(
              <>
                Chcę otrzymać kopię wysylanej wiadomości na podany adres e-mail.
              </>
            )
          }
          {...register("messageCopy"
            )
          }
          value={"true"}
        />

        {isSubmitSuccessful ? (
          <div className="flex flex-row gap-x-2">
            <img className="min-w-6 min-h-6 max-w-6 max-h-6" src={Check} /> <span className="font-head font-medium text-xl text-aluprofil-gray">Otrzymaliśmy Twoją wiadomość</span>
          </div>
        ) : (
          <div className="flex flex-row gap-x-2">
            <Button type={"submit"} errorMessage={errors.root?.message}>
              Wyślij wiadomość
            </Button>
          </div>
        )}
      </form>
    </>
  )
}

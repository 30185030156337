import { api } from "./api";
import { CategoryBaseResponse, CategoryResponse, TypeResponse } from "types";

export const getTypes = () => {
  return api.get<{
      types: TypeResponse[]
    }
  >(
    `/api/types`
  )

  .then(
    (x) => x.data
  )
}

export const getCategories = () => {
  return api.get<{
      categories: CategoryBaseResponse[]
      _links: {
        [key: string]: string
      }
    }
  >(
    `/api/categories`
  )

  .then(
    (x) => x.data
  )
}

export const getCategory = (
  categoryId: string
) => {
  return api.get<{
      category: CategoryResponse
      _links: {
        [key: string]: string
      }
    }
  >(
    `/api/categories/${categoryId}`
  )

  .then(
    (x) => x.data
  )
}

import { api, resolveMessage } from "./api";
import { MessageRequest } from "types";

export const sendMessage = (
  message: MessageRequest
) => {
  return api.post(`api/message`, {
    message: message
    }
  )

  .catch(
    (x) => {
      let errors = "Niespodziewany błąd"

      if (
        "errors" in x.response.data
      ) {
        errors = resolveMessage(
          x.response.data.errors
        )
      }

      return Promise.reject(errors)
    }
  )
}

import clsx from "clsx"
import { NavLink, useLocation } from "react-router-dom"
import { sitemap } from "data/sitemap"
import { selectFlatten } from "array/ArrayExtensions"

import Menu from "assets/Menu.svg"
import Aluprofil from "assets/Aluprofil.png"
import Header from "assets/Full/Header.png"

const menuitems = selectFlatten(
  sitemap, (x) => x.children ?? []
)

export const NavbarComponent = () => {
  const location = useLocation()

  return (
    <nav className="w-full bg-aluprofil-gray">
      <div className="mx-auto container">
        <div className="px-4 flex flex-col w-full h-[80px] lg:grid lg:grid-cols-3 lg:items-center">
          <div className="relative -mx-4 h-full flex px-4 justify-center items-center lg:-mx-0 lg:col-start-1 lg:px-0">
            <h1 className="z-10">
              <NavLink to="/">
                <img className="h-10 lg:h-12 w-auto" src={Aluprofil} alt="Aluminiowe systemy do drzwi przesuwnych, składanych, rozwieranych i przejściowych Aluprofil do zabudów meblowych" /><span className="sr-only">Aluminiowe systemy do drzwi przesuwnych, składanych, rozwieranych i przejściowych Aluprofil do zabudów meblowych</span>
              </NavLink>
            </h1>

            <div className="z-0 absolute right-0 top-0 mr-4 w-screen lg:mr-0 lg:w-screen-1/2 h-[80px] bg-no-repeat bg-right-top"
              style={{
                backgroundImage: `url(${Header})`,
                }
              }
            />
          </div>

          <input className="peer sr-only" type="checkbox" id="toggle" name="toggle" />

          <menu className="absolute left-0 top-[80px] right-0 w-full hidden flex-col p-3 bg-aluprofil-gray lg:relative lg:top-0 lg:col-start-2 lg:col-span-2 lg:justify-self-end lg:w-auto lg:h-full lg:flex lg:p-0 lg:flex-row peer-checked:flex">
            {sitemap

            .filter(
              (x) => x.inNavbarVisible
            )

            .map(
                (x) => {
                  if (
                    ! x.link
                  ) {
                    return null
                  }

                  const locationmenuitem = menuitems.find(
                    (y) => y.link === location.pathname
                  )

                  let isActive = false

                  if (
                    x.args
                  ) {
                    isActive = Object.entries(x.args).every(
                      ([ key ]) => locationmenuitem?.args && key in locationmenuitem?.args
                    )
                  }

                  return (
                    <li key={x.link} className="relative block">
                      <NavLink to={x.link}
                        className={
                          () => {
                            return clsx(
                              "h-full px-2 block font-head font-medium text-lg text-center text-white lg:place-content-center lg:hover:border-b-2 lg:hover:border-aluprofil-red", isActive ? "lg:bg-aluprofil-light/20" : ""
                            )
                          }
                        }
                      >
                        {x.name}
                      </NavLink>

                      {(
                          isActive && x.args && x.args["door"]
                      ) ? (
                        <ul className="w-full lg:absolute lg:left-0 lg:top-[80px] lg:right-0">
                          <li className="block">
                            <a className="w-full lg:h-[80px] block place-content-center font-head font-medium text-lg text-center text-white lg:bg-aluprofil-red lg:shadow-center-16 lg:shadow-aluprofil-red/50"
                              href="/download/Aluprofil_Olimp_3_7192_pl_PL.exe"
                            >
                              Pobierz program
                            </a>
                          </li>
                        </ul>
                      ) : null}
                    </li>
                  )
                }
              )
            }
          </menu>

          <label htmlFor="toggle" className="absolute left-0 top-0 z-[10000] h-[80px] flex flex-col justify-center p-4 bg-white lg:sr-only">
            <img className="w-10 h-10" src={Menu} alt="FacebMenuook" />
          </label>
        </div>
      </div>
    </nav>
  )
}

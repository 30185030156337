import { LayoutPage } from "pages/_Layout/Layout.page"
import { BlankComponent } from "sections/Blank/Blank.component"
import { FooterComponent } from "sections/Footer/Footer.component"
import { FunctionsComponent } from "sections/Functions/Functions.component"
import { NavbarComponent } from "sections/Navbar/Navbar.component"
import { GroupedOtherProductComponent } from "sections/Product/GroupedOtherProduct.component"

export const GroupedOtherProductPage = ({
    categoryId
  } : {
    categoryId: string
  }
) => {
  return (
    <LayoutPage>
      <>
        <NavbarComponent />

        <GroupedOtherProductComponent categoryId={categoryId} />

        <BlankComponent size="1/3" />

        <FunctionsComponent />

        <BlankComponent size="1/3" />

        <FooterComponent />
      </>
    </LayoutPage>
  )
}

import clsx from "clsx"
import { selectFlatten } from "array/ArrayExtensions"
import { sitemap } from "data/sitemap"
import { NavLink } from "react-router-dom"

import Aluprofil from "assets/Aluprofil.png"
import Facebook from "assets/Facebook.svg"
//import Instagram from "assets/Instagram.svg"
//import YouTube from "assets/YouTube.svg"
//import LinkedIn from "assets/LinkedIn.svg"
import Footer from "assets/Full/Footer.png"

export const FooterComponent = () => {
  return (
    <div className="relative w-full pb-screen-1/2w bg-aluprofil-light overflow-hidden lg:pb-0">
      <div className="mx-auto container">
        <div className="grid px-4 py-16 pb-0 grid-cols-1 gap-16 lg:grid-cols-2 lg:pb-16 lg:gap-0">
          <ul className="block columns-2 gap-6 space-y-6 lg:columns-3">
            {sitemap

            .filter(
              (x) => x.inFooterVisible
            )
            
            .map(
                (x) => {
                  const children = selectFlatten(
                    x.children ?? [], (y) => y.children ?? []
                  )

                  return (
                    <li key={x.name} className="break-inside-avoid">
                      <div className="flex flex-row">
                        {x.link ? (
                          <NavLink to={x.link}
                            className={
                              ({ isActive }) => clsx(
                                isActive ? "-mx-3 w-auto block px-3 text-aluprofil-red rounded-full ring-1 ring-inset ring-aluprofil-red" : "text-black/80 hover:text-aluprofil-red"
                              )
                            }
                          >
                            {x.name}
                          </NavLink>
                        ) : (
                          <span className={"font-head text-aluprofil-gray/80 uppercase"}>
                            {x.name}
                          </span>
                        )}
                      </div>

                      <ul>
                        {children
                        
                        .filter(
                          (y) => y.inFooterVisible
                        )

                        .map(
                            (y) => {
                                if (
                                  ! y.link
                                ) {
                                  return null
                                }

                                return (<li key={y.name} className="flex flex-row">
                                  <NavLink to={y.link}
                                    className={
                                      ({ isActive }) => clsx(
                                        isActive ? "-mx-3 w-auto block px-3 text-aluprofil-red rounded-full ring-1 ring-inset ring-aluprofil-red" : "text-black/80 hover:text-aluprofil-red"
                                      )
                                    }
                                  >
                                    {y.name}
                                  </NavLink>
                                </li>
                              )
                            }
                          )
                        }
                      </ul>
                    </li>
                  );
                }
              )
            }
          </ul>

          <div className="flex flex-col gap-6">
            <div className="z-10 text-sm text-aluprofil-gray lg:w-2/3">
              <img className="h-10 lg:h-12 w-auto" src={Aluprofil} alt="Aluprofil" />

              <br /><p>Od 2002 roku budujemy Wasze zaufanie dostarczając systemy aluminiowe z dożywotnią gwarancją na układy jezdne.</p>
            </div>

            <div className="z-10 space-y-2 text-sm text-aluprofil-gray"
              itemScope itemType="http://schema.org/Organization"
            >
              <p>
                <span itemProp="name">ALUPROFIL Sp. z o.o.</span>
              </p>

              <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                <p>
                  <span itemProp="streetAddress">Plac Czerwca 1976 roku Nr 7</span>
                </p>

                <p>
                  <span itemProp="postalCode">02-495</span> <span itemProp="addressLocality">Warszawa - Ursus</span>
                </p>
              </div>

              <p>
                <a href="phone:+48224783303" itemProp="telephone">
                  +48 22 478 33 03
                </a>
              </p>
            </div>

            <div className="z-10">
              <ul className="flex flex-row gap-2">
                <li>
                  <a href="https://www.facebook.com/AluprofilPolska" target="_blank">
                    <img className="w-9 h-9" src={Facebook} alt="Facebook" />
                  </a>
                </li>

                {/* <li>
                  <a href="https://www.instagram.com" target="_blank">
                    <img className="w-9 h-9" src={Instagram} alt="Instagram" />
                  </a>
                </li>

                <li>
                  <a href="https://www.youtube.com" target="_blank">
                    <img className="w-9 h-9" src={YouTube} alt="YouTube" />
                  </a>
                </li>

                <li>
                  <a href="https://www.linkedin.com" target="_blank">
                    <img className="w-9 h-9" src={LinkedIn} alt="LinkedIn" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="z-0 h-screen-w absolute left-4 bottom-0 right-0 bg-no-repeat bg-left-bottom lg:w-screen-1/2 lg:left-1/2"
        style={{
            backgroundImage: `url(${Footer})`
          }
        }
      />
    </div>
  )
}

import clsx from "clsx"
import { selectFlatten } from "array/ArrayExtensions"
import { sitemap } from "data/sitemap"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { getCategories } from "services/api.product"
import { CategoryBaseResponse } from "types"
import { categoriesSort } from "data/categoriesSort"
import { NavLink } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Button } from "components/Form/Button"

export const ProductsComponent = () => {
  const [ open, setOpen ] = useState<boolean>(
    false
  )

  const [ categories, setCategories ] = useState<{
      categories: CategoryBaseResponse[]
      _links: {
        [key: string]: string
      }
    }
  >({
      categories: [],
      _links: {}
    }
  )

  const {
    watch,
    setValue,
    register
  } = useForm<{
      isSlidingDoors: boolean
      isFoldingDoors: boolean
      isPathwayDoors: boolean
      isPullapartDoors: boolean
      isBoard10mm: boolean
      isBoard18mm: boolean
      isGlass4to8mm: boolean
      isGlass4plusBoard12mm: boolean
    }
  >()

  const isSlidingDoors = watch("isSlidingDoors")

  const isFoldingDoors = watch("isFoldingDoors")

  const isPathwayDoors = watch("isPathwayDoors")

  const isPullapartDoors = watch("isPullapartDoors")

  const isBoard10mm = watch("isBoard10mm")

  const isBoard18mm = watch("isBoard18mm")

  const isGlass4to8mm = watch("isGlass4to8mm")

  const isGlass4plusBoard12mm = watch("isGlass4plusBoard12mm")

  useEffect(
    () => {
      if (isSlidingDoors) {
        setValue("isFoldingDoors", false)
        setValue("isPathwayDoors", false)
        setValue("isPullapartDoors", false)
      }
    },
      [ isSlidingDoors ]
  )

  useEffect(
    () => {
      if (isFoldingDoors) {
        setValue("isSlidingDoors", false)
        setValue("isPathwayDoors", false)
        setValue("isPullapartDoors", false)
      }
    },
      [ isFoldingDoors ]
  )

  useEffect(
    () => {
      if (isPathwayDoors) {
        setValue("isSlidingDoors", false)
        setValue("isFoldingDoors", false)
        setValue("isPullapartDoors", false)
      }
    },
      [ isPathwayDoors ]
  )

  useEffect(
    () => {
      if (isPullapartDoors) {
        setValue("isSlidingDoors", false)
        setValue("isFoldingDoors", false)
        setValue("isPathwayDoors", false)
      }
    },
      [ isPullapartDoors ]
  )

  useEffect(
    () => {
      if (isBoard10mm) {
        setValue("isBoard18mm", false)
        setValue("isGlass4plusBoard12mm", false)
      }
    },
      [ isBoard10mm ]
  )

  useEffect(
    () => {
      if (isBoard18mm || isGlass4plusBoard12mm) {
        setValue("isBoard10mm", false)
      }
    },
      [ isBoard18mm, isGlass4plusBoard12mm ]
  )

  useEffect(
    () => {
      if (isGlass4plusBoard12mm) {
        setValue("isGlass4to8mm", false)
      }
    },
      [ isGlass4plusBoard12mm ]
  )

  useEffect(
    () => {
      if (isGlass4to8mm) {
        setValue("isGlass4plusBoard12mm", false)
      }
    },
      [ isGlass4to8mm ]
  )

  useEffect(
    () => {
      getCategories()

      .then(
        (x) => setCategories(x)
      )
    },
      []
  )

  const menuitems = selectFlatten(
    sitemap, (x) => x.children ?? []
  )

  const filteredcategories = categories.categories

  .filter(
    (x) => (
          x.attributes.isSlidingDoors
      ||  x.attributes.isFoldingDoors
      ||  x.attributes.isPathwayDoors
      ||  x.attributes.isPullapartDoors
    )
  )

  .filter(
    (x) => ((
        (isSlidingDoors && x.attributes.isSlidingDoors) || ! isSlidingDoors
      )
      && (
        (isFoldingDoors && x.attributes.isFoldingDoors) || ! isFoldingDoors
      )
      && (
        (isPathwayDoors && x.attributes.isPathwayDoors) || ! isPathwayDoors
      )
      && (
        (isPullapartDoors && x.attributes.isPullapartDoors) || ! isPullapartDoors
      )
      && (
        (isBoard10mm && x.attributes.isBoard10mm) || ! isBoard10mm
      )
      && (
        (isBoard18mm && x.attributes.isBoard18mm) || ! isBoard18mm
      )
      && (
        (isGlass4to8mm && (x.attributes.isGlass4mm || x.attributes.isGlass4dot5mm || x.attributes.isGlass6mm || x.attributes.isGlass6dot4mm || x.attributes.isGlass8mm)) || ! isGlass4to8mm
      )
      && (
        (isGlass4plusBoard12mm && x.attributes.isGlass4plusBoard12mm) || ! isGlass4plusBoard12mm
      )
    )
  )

  .sort(
    (a, b) => {
      const indexA = categoriesSort.indexOf(a.categoryId)
      const indexB = categoriesSort.indexOf(b.categoryId)

      return indexA - indexB
    }
  )

  .reduce(
    (acc, item) => {
      const group = item.categoryFullName.split("/")[0].trim()

      if (
        ! (group in acc)
      ) {
        acc[group] = []
      }

      acc[group].push(item)

      return acc
    },
      {} as { [key: string]: CategoryBaseResponse[] }
  )

  return (
    <>
      <Helmet>
        <title>Systemy drzwiowe w ALUPROFIL</title>

        <meta
          property="og:type"
          content="website"
        />

        <meta
          property="og:title"
          content="Systemy drzwiowe w ALUPROFIL"
        />

        <meta
          property="og:description"
          content="Od 2002 roku budujemy Wasze zaufanie dostarczając systemy aluminiowe z dożywotnią gwarancją na układy jezdne."
        />

        <meta
          name="description"
          content="Od 2002 roku budujemy Wasze zaufanie dostarczając systemy aluminiowe z dożywotnią gwarancją na układy jezdne."
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Systemy drzwiowe w ALUPROFIL",
            "description": "Od 2002 roku budujemy Wasze zaufanie dostarczając systemy aluminiowe z dożywotnią gwarancją na układy jezdne."
          }`}
        </script>
      </Helmet>

      <div className="w-full bg-aluprofil-light">
        <div className="mx-auto container">
          <div className="px-4 py-16">
            <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

            <h2 className="mb-16 w-full font-head font-normal text-5xl text-aluprofil-gray">
              Systemy drzwiowe
            </h2>

            <div className="flex flex-row gap-6 flex-wrap">
              <div className="w-full flex flex-row flex-wrap lg:w-auto">
                <label className="mb-2 w-full text-sm text-aluprofil-gray lg:mb-0 lg:w-20">
                  Wybierz typ drzwi:
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isSlidingDoors")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe001;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    przesuwne
                  </span>
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isFoldingDoors")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe003;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    składane
                  </span>
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isPullapartDoors")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe002;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    rozwierane
                  </span>
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isPathwayDoors")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe000;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    przejściowe
                  </span>
                </label>
              </div>

              <div className="w-full flex flex-row flex-wrap lg:w-auto">
                <label className="mb-2 w-full text-sm text-aluprofil-gray lg:mb-0 lg:w-20">
                  Wybierz typ wypełnienia:
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isBoard10mm")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe004;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    płyta 10 mm
                  </span>
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isBoard18mm")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe006;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    płyta 18 mm
                  </span>
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isGlass4to8mm")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe017;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    szkło 4 - 8 mm
                  </span>
                </label>

                <label className="cursor-pointer w-20 flex flex-col items-center text-aluprofil-gray hover:text-aluprofil-red">
                  <input type={"checkbox"} className="peer sr-only"
                    {...register("isGlass4plusBoard12mm")}
                  />

                  <span className="mb-2 block w-12 h-12 font-icon text-5xl/none peer-checked:text-aluprofil-red">
                    &#xe018;
                  </span>

                  <span className="w-full block text-sm text-center peer-checked:text-aluprofil-red">
                    szkło 4 mm + płyta 12 mm
                  </span>
                </label>
              </div>

              <div className="w-full flex flex-col">
                <ul
                  className={
                    clsx(
                      "w-full flex flex-col gap-6 lg:grid lg:gap-2 xl:gap-6 lg:grid-cols-6", open ? "lg:max-h-none" : "lg:max-h-[1120px] lg:overflow-hidden"
                    )
                  }
                >
                  {Object.entries(filteredcategories).map(
                      ([key, value]) => {
                        return (
                          <li key={key}>
                            <h3 className="h-16 grid place-items-center font-head font-semibold text-xl/none text-center text-white bg-aluprofil-gray">
                              {key}
                            </h3>

                            <ul className="grid grid-cols-2 gap-[1px] sm:grid-cols-3 md:grid-cols-4 lg:flex lg:flex-col">
                              {value.map(
                                  (x) => {
                                    const menuitem = menuitems.find(
                                      (y) => y.args?.door === x.categoryId
                                    )

                                    if (
                                      ! menuitem || ! menuitem.link
                                    ) {
                                      return null
                                    }

                                    return (
                                      <li key={x.categoryId}>
                                        <NavLink to={menuitem.link} className="h-66 flex flex-col justify-end p-6 bg-white/50 border-b-2 border-b-aluprofil-gray hover:bg-white/100 hover:border-b-aluprofil-red">
                                          {categories._links[`categoryGet.${x.categoryId}.imageAssetGet`] ? (
                                            <img className="mb-4 h-36 w-36"
                                              src={categories._links[`categoryGet.${x.categoryId}.imageAssetGet`]}
                                            />
                                          ) : null}

                                          <h4 className="w-full h-14 block font-medium text-lg text-center text-aluprofil-gray">
                                            {x.categoryName}
                                          </h4>
                                        </NavLink>
                                      </li>
                                    )
                                  }
                                )
                              }
                            </ul>
                          </li>
                        )
                      }
                    )
                  }
                </ul>

                {open ? null : (
                    <div className="hidden relative w-full flex-col items-center mt-16 after:content-[''] after:block after:absolute after:top-0 after:left-0 after:right-0 after:-mt-96 after:h-96 after:bg-gradient-to-t after:from-aluprofil-light lg:flex">
                      <Button type={"button"}
                        onClick={
                          () => setOpen(true)
                        }
                      >
                        Zobacz więcej
                      </Button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import clsx from "clsx";
import { InputHTMLAttributes, ReactNode, forwardRef } from "react";

export const Input = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & {
    label?: ReactNode
    hint?: ReactNode
    errorMessage?: ReactNode
  }
>(
  (
    {
      label,
      hint,
      errorMessage,
      ...props
    }, ref
  ) => {
    return (
      <div>
        {label ? (
          <label className="mb-2 block text-sm/none font-medium text-aluprofil-gray">
            {label}
          </label>
        ) : null}

        <div className="relative rounded-md shadow-sm">
          <input
            className={
              clsx(
                errorMessage ? "text-aluprofil-red ring-aluprofil-red placeholder:text-aluprofil-red focus:ring-aluprofil-red" : "text-black ring-aluprofil-gray placeholder:text-aluprofil-gray focus:ring-aluprofil-red", "block w-full border-0 px-3 py-2 text-base/none rounded-[16px] ring-1 ring-inset focus:border-teal focus:outline-none focus:ring-aluprofil-red"
              )
            }
            ref={ref} {...props}
          />

          {errorMessage ? (
            <>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="h-5 w-5 text-aluprofil-red">!</span>
              </div>
            </>
          ) : null}
        </div>

        {errorMessage ? (
          <>
            <p className="mt-1 text-sm text-aluprofil-red">
              {errorMessage}
            </p>
          </>
        ) : null}

        {hint ? (
          <p className="mt-1 text-sm text-aluprofil-gray">
            {hint}
          </p>
        ) : null}
      </div>
    )
  }
)

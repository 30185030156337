import { LayoutPage } from "pages/_Layout/Layout.page"
import { AdventagesComponent } from "sections/Advantages/Adventages.component"
import { BlankComponent } from "sections/Blank/Blank.component"
import { FeaturesComponent } from "sections/Features/Features.component"
import { FooterComponent } from "sections/Footer/Footer.component"
import { NavbarComponent } from "sections/Navbar/Navbar.component"

export const FeaturesPage = () => {
  return (
    <LayoutPage>
      <>
        <NavbarComponent />

        <AdventagesComponent />

        <BlankComponent size="1/3" />

        <FeaturesComponent />

        <BlankComponent size="1/3" />

        <FooterComponent />
      </>
    </LayoutPage>
  )
}

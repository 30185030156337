import { ReactNode, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { selectFlatten } from "array/ArrayExtensions"
import { sitemap } from "data/sitemap"

import Nowe001EX from "assets/Full/Nowe-001-EX.jpg"

const menuitems = selectFlatten(
  sitemap, (x) => x.children ?? []
)

export const LayoutPage = ({
  children
 } : {
  children: ReactNode
 }
) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [atBottom, setAtBottom] = useState<boolean>(
    false
  );

  const [countdown, setCountdown] = useState<number | null>(
    null
  );

  const handleScroll = () => {
    const atBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;

    setAtBottom(atBottom);
  };

  useEffect(
    () => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    },
      []
  );

  useEffect(
    () => {
      if (atBottom) {
        const timer = setTimeout(
          () => {
            setCountdown(3);
          },
            500
        );

        return () => clearTimeout(timer);
      }
      else {
        setCountdown(null);
      }
    },
      [ atBottom ]
  );

  useEffect(
    () => {
      if (countdown === null) {
        return;
      }

      if (countdown > 0) {
        const countdownTimer = setTimeout(
          () => {
            setCountdown(countdown - 1);
          },
            1000
        );

        return () => clearTimeout(countdownTimer);
      }
      else {
        const x = menuitems.filter(
          (x) => x.link
        )


        const index = x.findIndex(
          x => x.link === location.pathname
        );

        const menuitem = x[
          mod(index + 1, x.length)
        ]

        if (
          menuitem && menuitem.link
        ) {
          navigate(menuitem.link)
        }
      }
    },
      [ countdown ]
  );

  return (
    <>
      <div>
        <div className="-z-50 fixed top-0 left-0 right-0 bottom-0 bg-center bg-cover bg-no-repeat"
          style={{
              backgroundImage: `url(${Nowe001EX})`
            }
          }
        />

        {children}

        <div className="w-full h-screen bg-center bg-cover bg-no-repeat"
          style={{
              backgroundImage: `url(${Nowe001EX})`
            }
          }
        />
      </div>

      {countdown !== null && (
        <>
          <div className="fixed top-0 bottom-0 left-0 right-0 grid place-content-center">
            <div className="w-48 flex flex-col aspect-w-1 aspect-h-1 rounded-full bg-white/70 border-8 border-aluprofil-gray">
              <div className="grid place-content-center gap-y-2">
                <span className="block text-center text-aluprofil-gray">
                  Następna strona za:
                </span>

                <span className="block text-center text-5xl text-aluprofil-gray">
                  {countdown}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

function mod (index: number, length: number) {
  return ((index % length) + length) % length;
}

import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react";

export const Button = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement> & {
    errorMessage?: ReactNode
  }
>(
  (
    {
      errorMessage,
      className,
      children,
      ...props
    }, ref
  ) => {
    return (
      <div>
        <button className="w-auto block px-8 py-2 font-bold text-lg uppercase text-aluprofil-red bg-white rounded-full ring-1 ring-inset ring-aluprofil-red"
          ref={ref} {...props}
        >
          {children}
        </button>

        {errorMessage ? (
          <>
            <p className="mt-1 text-sm text-aluprofil-red">
              {errorMessage}
            </p>
          </>
        ) : null}
      </div>
    )
  }
)
import { Helmet } from "react-helmet"

export const PolicyComponent = () => {
  return (
    <>
      <Helmet>
        <title>Polityka prywatności w ALUPROFIL</title>

        <meta
          property="og:type"
          content="website"
        />
        
        <meta
          property="og:title"
          content="Polityka prywatności w ALUPROFIL"
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Polityka prywatności w ALUPROFIL"
          }`}
        </script>
      </Helmet>

      <div className="w-full bg-white">
        <div className="mx-auto container">
          <div className="flex flex-col px-4 py-16 place-items-center">
            <hr className="mb-16 w-full border-t-2 border-aluprofil-gray/20" />

            <h2 className="mb-16 w-full font-head font-normal text-5xl text-aluprofil-gray">
              Polityka prywatności
            </h2>

            <div className="prose">
  <h3>SPIS TREŚCI</h3>

  <p>1. Przepisy ogólne<br />
  2. Zasady przetwarzania danych osobowych<br />
  3. Podstawa prawna przetwarzania danych osobowych<br />
  4. Zakres przetwarzania danych osobowych<br />
  5. Sposób postępowania z danymi osobowymi<br />
  6. Uprawnienia osób, których dane dotyczą<br />
  7. Polityka cookies<br />
  8. Polityka prywatności</p>

  <h3>§1 Przepisy ogólne</h3>

  <p>1.1. Administratorem danych osobowych jest Aluprofil Sp. z o.o. z siedzibą Plac Czerwca 1976 roku 7, 02-495 Warszawa, NIP: 522-26-58-441, REGON: 015256950, KRS: 0000133457 (dalej jako „Administrator“).<br />
  1.2. Dane osobowe przetwarzane są zgodnie z powszechnie obowiązującymi przepisami prawa, w tym przede wszystkim z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) z dnia 27 kwietnia 2016 r (dalej jako RODO).<br />
  1.3. Wszelkie pytania, wnioski i uwagi dotyczące ochrony prywatności prosimy kierować do ustanowionego Inspektora Ochrony Danych Osobowych poprzez adres e-mail: iod@aluprofil.pl</p>

  <h3>§2 Zasady przetwarzania danych osobowych</h3>

  <p>2.1. W czasie przetwarzania danych osobowych Administrator stosuje następujące zasady:<br />
  2.1.1. Legalności – przetwarzanie danych osobowych może się odbywać wyłącznie, gdy zaistnieje co najmniej jedna z przewidzianych prawem przesłanek;<br />
  2.1.2. Celowości – aby dane mogły być przetwarzane, musi istnieć ku temu konkretny, wyraźny i prawnie uzasadniony cel. Jeżeli przetwarzanie służy różnym celom, potrzebna jest zgoda/podstawa na wszystkie cele.<br />
  2.1.3. Adekwatności – przetwarzamy tylko te dane, które są niezbędne ze względu na cel ich zbierania;<br />
  2.1.4. Merytorycznej poprawności – Administrator jest zobowiązany do tego, aby dane przez niego zbierane były poprawne i w razie potrzeby uaktualniane. Powinien oceniać wiarygodność źródła pozyskania danych oraz wdrożyć sposób weryfikowania prawdziwości przetwarzanych danych;<br />
  2.1.5. Czasowości – zasada ograniczenia przechowywania danych – nie przetwarzamy danych dłużej niż jest to konieczne. W praktyce, Administrator dokonuje indywidualnej, konkretnej oceny, przez jaki okres powinien przetwarzać dane osobowe. Okres przechowywania jest uzależniony od wielu zmiennych.<br />
  2.1.6. Integralności i poufności danych – dane osobowe są zabezpieczone za pomocą odpowiednich środków technicznych i organizacyjnych. Administrator dokłada najwyższej możliwej staranności, by chronić przekazane informacje przed wszelkimi zagrożeniami.</p>

  <h3>§3 Podstawa prawna przetwarzania danych osobowych</h3>

  <p>3.1. Zgodnie ze wskazaną powyżej zasadą legalności, przetwarzanie danych osobowych może się odbywać wyłącznie, gdy zaistnieje co najmniej jedna z przewidzianych prawem przesłanek.<br />
  3.2. Administrator będzie uprawniony do przetwarzania danych osobowych jeśli wystąpi jedna z przedstawionych sytuacji:<br />
  3.2.1. osoba, której dane dotyczą wyraziła zgodę na przetwarzanie danych osobowych w jednym lub większej liczbie określonych celów (art. 6 ust. 1 lit. a RODO). Na tej podstawie Administrator będzie przetwarzać dane osobowe.<br />
  3.2.2. przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy (art. 6 ust. 1 lit. b RODO).<br />
  3.2.3. przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO). Na tej podstawie Administrator będzie przetwarzać dane osobowe w szczególności w celu wystawienia faktur, spełnienia obowiązków rachunkowych;<br />
  3.2.4. przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora (art. 6 ust. 1 lit. f RODO). Na tej podstawie Administrator będzie przetwarzać dane osobowe w szczególności w celu dochodzenia roszczeń, prowadzenia statystyk.</p>

  <h3>§4 Zakres przetwarzania danych osobowych</h3>

  <p>Administrator przetwarza następujące kategorie danych osobowych:<br />
  4.1. Dane osobowe klientów<br />
  4.1.1. Dane osobowe będą przetwarzane przez Administratora w celu wykonania umowy sprzedaży lub świadczenia usług drogą elektroniczną oraz w celu wykonania czynności na żądanie osoby, której dane dotyczą, zawarcia umowy, jej realizacji oraz świadczenia obsługi posprzedażowej.<br />
  4.1.2. Zakres przetwarzania danych (zakres maksymalny) obejmuje: imię i nazwisko, adres poczty elektronicznej, numer telefonu kontaktowego, adres dostawy (ulica, numer domu, numer lokalu, kod pocztowy, miejscowość, kraj), adres zamieszkania, jeżeli jest inny niż adres dostawy. W przypadku klientów prowadzących działalność gospodarczą przetwarzane będą również dane identyfikujące tę działalność (nazwa firmy, numer identyfikacji podatkowej (NIP), adres prowadzonej działalności/siedziby), dane identyfikujące przedstawiciela klienta.<br />
  4.1.3. Dane osobowe są przetwarzane przez czas niezbędny do prawidłowego wykonania umowy lub świadczonej usługi. Administrator przestaje przetwarzać dane osobowe, gdy wygasną wszelkie uzasadnione powinności i ryzyka (w szczególności, gdy upłynie okres przedawnienia danych roszczeń, obowiązki wynikające z przepisów prawa).<br />
  4.1.4. Podanie danych osobowych jest dobrowolne. Jednak odmowa ich podania (lub podanie danych nierzetelnych, nieprawdziwych) może uniemożliwić zawarcie i prawidłową realizację umowy.<br />
  4.2. Dane osobowe osób, które przystąpiły do newslettera<br />
  4.2.1. Dane te Administrator przetwarza w celu cyklicznego dostarczania na podany adres elektroniczny i/lub numer telefonu informacji handlowych oraz marketingowych.<br />
  4.2.2. Dane osobowe obejmować będą:<br />
  4.2.2.1. imię i nazwisko,<br />
  4.2.2.2. adres poczty elektronicznej,<br />
  4.2.2.3. numer telefonu.<br />
  4.2.3. Podanie danych osobowych jest zawsze dobrowolne. Wyrażona zgoda może zostać w każdym czasie cofnięta. Odmowa podania danych pozostaje bez wpływu na zawarcie i realizację umowy.<br />
  4.2.4. Dane osobowe przetwarzane na podstawie zgody Administrator wykorzystuje przez czas określony w zgodzie, a w każdym przypadku do czasu jej odwołania. Z chwilą wycofania zgody, ustaje podstawa do ich dalszego wykorzystania.<br />
  4.3. Dane osób kontaktujących się z Administratorem<br />
  4.3.1. Dane te Administrator przetwarza, by móc udzielić odpowiedzi na pytania, wnioski bądź uwagi sformułowane dostępnymi kanałami<br />
  4.3.2. Dane te, w zależności od sytuacji mogą obejmować:<br />
  4.3.2.1. imię i nazwisko,<br />
  4.3.2.2. adres poczty elektronicznej,<br />
  4.3.2.3. nr telefonu,<br />
  4.3.2.4. adres IP<br />
  4.3.3. Podanie danych osobowych jest dobrowolne. Jednak odmowa ich podania (lub podanie danych nierzetelnych, nieprawdziwych) może uniemożliwić nam kontakt i udzielenie oczekiwanych odpowiedzi.<br />
  4.4. Dane osobowe kontrahentów, partnerów <br />
  4.4.1. Dane osobowe będą przetwarzane przez Administratora w celu wykonania danej umowy z kontrahentem, realizacji postanowień programu oraz w celu wykonania czynności na żądanie osoby, której dane dotyczą, zawarcia umowy, a także jej realizacji.<br />
  4.4.2. Zakres przetwarzania danych (zakres maksymalny) obejmuje: dane identyfikujące działalność gospodarczą (imię i nazwisko, nazwa firmy, numer identyfikacji podatkowej (NIP), adres prowadzonej działalności/siedziby), adres poczty elektronicznej, numer telefonu kontaktowego, dane identyfikujące przedstawiciela kontrahenta lub partnera.<br />
  4.4.3. Dane osobowe są przetwarzane przez czas niezbędny do prawidłowego wykonania umowy lub realizacji programu. Administrator przestaje przetwarzać dane osobowe, gdy wygasną wszelkie uzasadnione powinności i ryzyka (w szczególności, gdy upłynie okres przedawnienia danych roszczeń, obowiązki wynikające z przepisów prawa).<br />
  4.4.4. Podanie danych osobowych jest dobrowolne. Jednak odmowa ich podania (lub podanie danych nierzetelnych, nieprawdziwych) może uniemożliwić zawarcie i prawidłową realizację umowy.<br />
  4.5. Dane uczestników targów, dni otwartych innych podobnych wydarzeń, którzy przystąpili do ankiety<br />
  4.5.1. Dane osobowe będą przetwarzane przez Administratora w celu przeprowadzenia ankiety. Celem ankiety jest zbadanie poziomu satysfakcji, podwyższenie jakości oferowanych produktów, usług i standardów, nawiązanie i podtrzymanie relacji handlowej.<br />
  4.5.2. Zakres przetwarzania danych (zakres maksymalny) obejmuje: imię i nazwisko, adres poczty elektronicznej, numer telefonu kontaktowego, dane adresowe, nazwę działalności gospodarczej, indywidualne opinie oraz opis doświadczeń ankietowanego.<br />
  4.5.3. Dane osobowe są przetwarzane przez czas niezbędny do prawidłowego przeprowadzenia ankiety.<br />
  4.5.4. Podanie danych osobowych jest dobrowolne. Podanie danych jest jednak niezbędne, by móc przystąpić do ankiety i przekazać zawarte w ankiecie informacje.<br />
  4.6. Dane uczestników szkoleń<br />
  4.6.1. Dane osobowe będą przetwarzane przez Administratora w celu przeprowadzenia szkoleń.<br />
  4.6.2. Zakres przetwarzania danych (zakres maksymalny) obejmuje: dane identyfikujące działalność gospodarczą (imię i nazwisko, nazwa firmy, numer identyfikacji podatkowej (NIP), adres prowadzonej działalności/siedziby), adres poczty elektronicznej, numer telefonu kontaktowego.<br />
  4.6.3. Dane osobowe są przetwarzane przez czas niezbędny do prawidłowego przeprowadzenia szkolenia.<br />
  4.6.4. Podanie danych osobowych jest dobrowolne. Jednak odmowa ich podania (lub podanie danych nierzetelnych, nieprawdziwych) może uniemożliwić udział w przeprowadzeniu szkolenia.<br />
  4.7. Dane autorów nadesłanych aranżacji<br />
  4.7.1. Dane osobowe będą przetwarzane przez Administratora w celu publikacji nadesłanej aranżacji wraz z danymi identyfikacyjnymi na https://www.aluprofil.pl oraz na https://pl-pl.facebook.com/AluprofilPolska/<br />
  4.7.2. Zakres przetwarzania danych (zakres maksymalny): imię i nazwisko autora aranżacji.<br />
  4.7.3. Dane osobowe są przetwarzane przez czas niezbędny do publikacji aranżacji.<br />
  4.7.4. Podanie danych osobowych jest dobrowolne. Jednakże, chcąc otrzymać na preferencyjnych warunkach produkty celem wykonania aranżacji, klient musi wyrazić zgodę na jej publikację wraz z danymi identyfikacyjnymi.</p>

  <h3>§5 Sposób postępowania z danymi osobowymi</h3>

  <p>5.1. Administrator wykorzystuje dane osobowe w celu, dla którego zostały pozyskane. Ponadto, dane mogą zostać wykorzystane do celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora, takich jak:<br />
  5.1.1. wykrywanie nadużyć i zapobieganie im,<br />
  5.1.2. ustalanie, obrona i dochodzenie roszczeń,<br />
  5.1.3. tworzenia zestawień, analiz i statystyk.<br />
  5.2. Przetwarzane dane osobowe nigdy nie trafią w niepowołane ręce. Nie będą przedmiotem obrotu. Mogą natomiast zostać przekazane:<br />
  5.2.1. podmiotom/organom upoważnionym na podstawie przepisów prawa,<br />
  5.2.2. podmiotom, którym przekazanie danych osobowych jest niezbędne dla realizacji określonej czynności (np. obsługa księgowa, dokonanie płatności, obsługa informatyczna, dostarczenie produktów).<br />
  5.3. Wszystkie podmioty, które mogą mieć styczność z danymi osobowymi, na mocy odrębnych uregulowań, zostały zobowiązane do ich starannego zabezpieczenia, zachowania w poufności, nieudostępniania osobom nieupoważnionym.<br />
  5.4. Administrator nie stosuje zautomatyzowanego profilowania.<br />
  5.5. Dane osobowe nie będą przetwarzane poza terenem EOG (Europejskiego Obszaru Gospodarczego).</p>

  <h3>§6 Uprawnienia osób, których dane dotyczą</h3>

  <p>6.1. Zasadą jest, że osoby, których dane są przetwarzane mają prawo do:<br />
  6.1.1. dostępu do treści swoich danych i ich sprostowania,<br />
  6.1.2. usunięcia swoich danych,<br />
  6.1.3. ograniczenia przetwarzania danych osobowych,<br />
  6.1.4. przenoszenia danych lub uzyskania ich kopii,<br />
  6.1.5. cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,<br />
  6.1.6. wniesienia sprzeciwu co do przetwarzania danych osobowych.<br />
  6.2. Szczegółowe warunki wykonywania wskazanych wyżej praw wskazane są w art. 15-21 RODO.<br />
  6.3. W celu realizacji opisanych uprawnień, należy skontaktować się pod adresem: iod@aluprofil.pl<br />
  6.4. Każdy wniosek zostanie rzetelnie rozpoznany przez Administratora i spotka się z odpowiedzią.<br />
  6.5. Opisane powyżej uprawnienia nie mają charakteru absolutnego (w praktyce więc może się zdarzyć, że pomimo otrzymania żądania, Administrator nie będzie mógł np. usunąć danych, ponieważ, zgodnie z literą prawa, ich przetwarzanie będzie nadal konieczne).<br />
  6.6. Osobom, których dane dotyczą przysługuje również prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, w szczególności, gdy przetwarzanie danych osobowych narusza przepisy RODO.</p>

  <h3>§7 Polityka cookies</h3>

  <p>7.1. Strona internetowa www.aluprofil.pl wykorzystuje pliki cookies. Administrator wykorzystuje następujące rodzaje plików cookies:<br />
  7.1.1. "niezbędne" pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach strony, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach strony;<br />
  7.1.2. pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach strony;<br />
  7.1.3. "wydajnościowe" pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych;<br />
  7.1.4. "funkcjonalne" pliki cookies, umożliwiające "zapamiętanie" wybranych przez użytkownika ustawień i personalizację interfejsu użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.<br />
  7.2. Pliki cookies nie są w żaden sposób łączone i kojarzone z innymi podanymi przez użytkowników informacjami.<br />
  7.3. Użytkownicy strony www.aluprofil.pl mają prawo wyłączyć pliki cookies (wszystkie bądź poszczególne rodzaje) w ustawieniach przeglądarki. Korzystanie ze strony www.aluprofil.pl bez zmiany tych ustawień będzie traktowane jako wyraźne działanie potwierdzające.<br />
  W każdej chwili mogą Państwo usunąć dane osobowe z naszej bazy poprzez wysłanie wiadomości e-mail na adres iod@aluprofil.pl.</p>


  <h3>§8 Polityka prywatności</h3>

  <p>8.1 Prawa autorskie do materiałów zawartych na stronie internetowej www.aluprofil.pl należą do spółki ALUPROFIL SP. Z O.O. Wszelkie materiały tekstowe, zdjęciowe, graficzne, dźwiękowe, filmowe oraz ich układ na stronie internetowej www.aluprofil.pl są prawnie chronione zgodnie z Ustawą o prawie autorskim i prawach pokrewnych z dnia 4 lutego 1994 roku (Dz. U. z 2006 r. Nr 90 poz. 631 z późn. zm.).<br />
  8.2 Kopiowanie dla celów komercyjnych, dystrybucja, modyfikacja oraz publikacja, bez pisemnej zgody ALUPROFIL SP. Z O.O. jest zabroniona. Wykorzystywanie zawartości strony internetowej – pobieranie, przechowywanie, drukowanie i kopiowanie prezentowanych treści, może jedynie odbywać się w celach prywatnych. Nazwa i logo ALUPROFIL jak również pozostałe nazwy i znaki graficzne umieszczone na stronie są zastrzeżone.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

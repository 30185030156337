export const groupsSort: string[] = [
  "41aa652c-0000-0000-8476-3625b82cfc79", // TOR GÓRNY EXCLUSIVE 42/I
  "604ca40a-0000-0000-8ee4-256fd2140ef9", // TOR GÓRNY EXCLUSIVE 82/II
  "7c3ef333-0000-0000-b12d-65561b4b9467", // TOR GÓRNY MEDIUM 29/I
  "7ac41c21-0000-0000-bc26-d901f9ad1aad", // TOR GÓRNY MEDIUM 56/II
  "27abb289-0000-0000-8413-fc8eda8514c3", // TOR GÓRNY PREMIUM 38/I
  "0174154d-0000-0000-97e1-c3e8643638ba", // TOR GÓRNY PREMIUM 75/II
  "7a0e6649-0000-0000-9efd-c7cac671f1c8", // TOR DOLNY POJEDYNCZY N
  "2c79fc0f-0000-0000-9fbe-1e707861c2f8", // TOR DOLNY POJEDYNCZY N PLUS
  "421c98e6-0000-0000-9baf-7309733ba221", // ZAŚLEPKA DO TORU POJEDYNCZEGO N PLUS
  "7ba69dd7-0000-0000-9073-9599969065f1", // TOR DOLNY POJEDYNCZY N CARPET
  "2b1f7a52-0000-0000-9d13-e83e6740f77a", // TOR DOLNY POJEDYNCZY W
  "60d86079-0000-0000-9d03-30fa724ecb95", // TOR DOLNY POJEDYNCZY W PLUS
  "23971130-0000-0000-bd7c-a12a5c1f5dd1", // PROFIL MONTAŻOWY POJEDYNCZY
  "1f9ff458-0000-0000-a003-da451e37fba4", // PROFIL MONTAŻOWY PODWÓJNY
  "53c11448-0000-0000-bbea-bcd62d0ccb52", // KĄTOWNIK L-17
  "006c8855-0000-0000-93d2-5e39047119be", // KĄTOWNIK L-11
  "2b2f9293-0000-0000-83d7-e5d11d66336e", // KĄTOWNIK L-18
  "397273a5-0000-0000-8a55-9ee09ad061dc", // ŁĄCZNIK H-17/18
  "774f9b4c-0000-0000-aea8-763aee7b3266", // ŁĄCZNIK H-4/18
  "4fbc4342-0000-0000-92bb-d1074f8636ad", // ŁĄCZNIK H-8/18
  "277c680b-0000-0000-a69a-4d12e728ec46", // ŁĄCZNIK H-28/18
  "7a63489b-0000-0000-9cad-284885d494b5", // ŁĄCZNIK H-35/18
  "50e3a45c-0000-0000-9445-0aaeccdde963", // ŁĄCZNIK H-25/10
  "11704bd4-0000-0000-bd30-db90dc28297d", // ŁĄCZNIK H-17/10
  "09df1087-0000-0000-8786-a0d40e2ff975", // ŁĄCZNIK H-18/10
  "01b1fd10-0000-0000-9507-bbc3870be259", // ŁĄCZNIK H-30/10
  "79f8e5b9-0000-0000-8590-48ba00f7d86e", // ŁĄCZNIK H-6/4
  "4129cb48-0000-0000-b534-d7b5355eaf6f", // TEOWNIK T-4
  "7938893b-0000-0000-8469-65f405a0a21d", // TEOWNIK T-16
  "1a14c2cd-0000-0000-8e9f-bed0b02c072c", // PŁASKOWNIK P-10
  "27de4dd9-0000-0000-b81e-2b6abe7b0054", // PŁASKOWNIK P-18 Z KLEJEM
  "7b83810b-0000-0000-90a8-26f37b651e3b", // PŁASKOWNIK P-20
  "19135749-0000-0000-b394-73eae48d78d8", // PŁASKOWNIK P-45 Z KLEJEM
  "1ab251d7-0000-0000-8281-17594d5dc868", // WÓZEK DOLNY LEXUS 18 (ZESTAW 2 SZT)
  "21933734-0000-0000-8a03-8fdc0119c14e", // WÓZEK DOLNY LEXUS 18 ST (ZESTAW 2 SZT)
  "099b6dca-0000-0000-93b3-4d7614cdd05c", // WÓZEK DOLNY MATRIX 10 (ZESTAW 2 SZT)
  "77fff1b8-0000-0000-a931-2c47e17881d0", // WÓZEK DOLNY PROTON 18 (ZESTAW 2 SZT)
  "210390d0-0000-0000-8e99-00c9e00059b3", // SZABLON DO FREZOWANIA GNIAZDA POD WÓZEK DOLNY LEXUS 18
  "2a2d8144-0000-0000-9d42-8951eef5192a", // SZABLON DO FREZOWANIA GNIAZDA POD WÓZEK DOLNY PROTON 18
  "05466a89-0000-0000-9515-5e5a2ae4248e", // SZABLON DO FREZOWANIA GNIAZDA POD WÓZKI SOLO 18
  "1a7fc6a8-0000-0000-b05f-39b66db4ee09", // KLIN DO PŁYTY
  "0294fa0f-0000-0000-9804-b69aec6e8add", // KLIN DO SZKŁA
  "2b3151d1-0000-0000-bd3d-3bda39b2e978", // FOLIA DO LUSTRA
  "5c43798c-0000-0000-96d1-b373ef2f3874", // TAŚMA DWUSTRONNA
  "00b63188-0000-0000-a3cd-54fd65583569", // ZAMEK DO DRZWI PRZESUWNYCH (SYSTEM #18)
]

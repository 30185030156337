function selectFlatten<T>(e: T[], f: (item: T) => T[]): T[] {
  const items: T[] = [];

  for (const i of e) {
      items.push(i);

      for (const j of selectFlatten(f(i), f)) {
          items.push(j);
      }
  }

  return items;
}

export {
  selectFlatten
}

import { ReactNode } from "react"

const fetures: {
  name: ReactNode
  desc: ReactNode
  icon: ReactNode
}[] = [
  { name: "Dobierz optymalną długość profili", desc: "Zmniejsz ilość odpadów - a tym samym koszt zakupu - wybierając optymalną długość profili od 1.70m do 6.00m.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe015;</span>) },
  { name: "Dostosuj kolor profili do wypełnienia", desc: "W zależności od oczekiwań klienta zmień wygląd profili lakierując je na 1 z 200 kolorów RAL lub dekorując folią drewnopodobną lub PCV.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00d;</span>) },
  { name: "Dobierz odpowiedni łącznik", desc: "Z 11 dostępnych łączników dobierz ten, który spełnia oczekiwania estetyczne i techniczne projektowanej zabudowy.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe014;</span>) },
  { name: "Zainstaluj drzwi przesuwne w płytkich wnękach", desc: "Korzystając z systemu o głębokości 56mm zainstalujesz drzwi przesuwne we wnęce, w której klasyczne tory będą zbyt głębokie.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00a;</span>) },
  { name: "Zaoferuj komfortowe rozwiązania", desc: "Podnieś komfort korzystania z zabudowy z drzwiami przesuwnymi instalując samodomykacze skrajne i środkowy.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe011;</span>) },
  { name: "Zainstaluj drzwi przesuwne w wysokich pomieszczeniach", desc: "Korzystając z rączek o długości do 3,50m zainstaluj drzwi przesuwne w naprawdę wysokich pomieszczeniach.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe009;</span>) },
  { name: "Dobierz tor do podłoża", desc: "Licuj tor dolny z podłożem instalując go w płycie meblowej, wykładzinie, panelu lub terakocie.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe012;</span>) },
  { name: "Zaoferuj dożywotnią gwarancję na układy jezdne", desc: "Dzięki zastosowaniu trwalszych materiałów i nowoczesnej produkcji wyróżnij swoją ofertę dożywotnią gwarancją na układy jezdne.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe008;</span>) },
]

export const FeaturesComponent = () => {
  return (
    <div className="w-full bg-white">
      <div className="mx-auto container">
        <div className="px-4 py-16">
          <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

          <h2 className="mb-16 w-full font-head font-normal text-5xl text-aluprofil-gray">
            Korzyści
          </h2>

          <div className="grid grid-cols-1 flex-wrap gap-x-6 gap-y-16 md:grid-cols-2 lg:grid-cols-3">
            {fetures.map(
                (feature, index) => (
                  <div key={index} className="flex flex-col gap-4">
                    <div className="w-18 h-18">
                      {feature.icon}
                    </div>

                    <div className="h-20 relative font-head font-bold text-2xl text-aluprofil-gray after:content-[''] after:absolute after:left-0 after:bottom-0 after:mt-4 after:w-12 after:h-0.25 after:block after:border-b after:border-b-aluprofil-red">
                      {feature.name}
                    </div>

                    <div className="text-base text-aluprofil-gray">
                      {feature.desc}
                    </div>
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

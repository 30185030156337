import clsx from "clsx"

export const BlankComponent = ({
    size
  } : {
    size: "1" | "1/2" | "1/3"
  }
) => {
  return (
    <div className={
        clsx(
          "w-full h-screen-1/2", size === "1" ? "h-screen" : size === "1/2" ? "h-screen-1/2" : "h-screen-1/3"
        )
      }
     />
  )
}

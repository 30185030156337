import clsx from "clsx"
import { useState } from "react"

const items = [
  { year: 2002, title: "Rozpoczęcie działalności firmy", description: "Bazując na naszym wieloletnim doświadczeniu w branży meblowej rozpoczynamy działalność oferując nowoczesne rozwiązania w systemach drzwi przesuwnych do zabudowy wnętrz." },
  { year: 2005, title: "Wprowadzenie do oferty systemów drzwiowych", description: "Rozszerzamy ofertę o innowacyjne kształty profili aluminiowych w systemach drzwi przesuwnych, składanych, rozwieranych i przejściowych." },
  { year: 2007, title: "Przeprowadzka do nowej siedziby", description: "Przeprowadzamy się do nowego budynku handlowo – magazynowo – usługowego mieszczącego się w warszawskiej dzielnicy Ursus. Pozwala nam to zaspokoić nasze potrzeby organizacyjne w jednej lokalizacji." },
  { year: 2008, title: "Wprowadzenie do oferty Systemu Kreator", description: "Wprowadzamy do naszej oferty System Kreator. Zastosowane w nim rozwiązania dają szerokie możliwości aranżacji wnętrz od regałów, ścianek działowych po kabiny sanitarne i boksy biurowe." },
  { year: 2010, title: "Udostępnienie stolarzom programu Aluprofil Visual", description: "Jako jedni z pierwszych na rynku udostępniamy bezpłatny program umożliwiającego projektowanie skrzydeł drzwiowych do zabudowy wnętrz." },
  { year: 2011, title: "Wprowadzenie do oferty akcesoriów meblowych", description: "Wprowadzamy kolekcję akcesoriów meblowych, wśród których są szuflady o różnym zastosowaniu, ruchome półki na buty, owalny drążek czy wysuwane lustro z wnętrza szafy." },
  { year: 2013, title: "Wprowadzenie do oferty linii Medium 56 i Exclusive 82", description: "Jednym z najważniejszych punktów w naszej historii jest wprowadzenie linii produktowych:  Medium 56, charakteryzująca się najmniejszą na rynku głębokością systemu (56 mm) oraz Exclusive 82, skierowana do odbiorców poszukujących rozwiązań z najwyższej półki." },
  { year: 2014, title: "Wprowadzenie do oferty złączy meblowych", description: "Jako pierwsi na rynku tworzymy nową formą złączy meblowych, składających się z solidnego metalowego kątownika i estetycznej wsuwanej zaślepki z tworzywa." },
  { year: 2015, title: "Udostępnienie stolarzom programu Aluprofil Olimp", description: "Udostępniamy nową, ulepszoną wersję bezpłatnego programu umożliwiającego projektowanie skrzydeł drzwiowych pod nazwą Olimp oferującą teraz dużo szersze możliwości projektowe." },
  { year: 2017, title: "Uruchomienie nowego parku maszynowego", description: "Uruchamiamy park maszynowy do produkcji wnętrz. Krok ten pozwala nam w bardziej kompleksowy sposób spełniać oczekiwania klientów." },
  { year: 2018, title: "Odświeżenie identyfikacji wizualnej firmy", description: "Po przeszło 15 latach działalności odświeżamy wizerunek wprowadzając nowe logo wraz z nową identyfikację wizualną firmy." },
  { year: 2019, title: "Udostępnienie stolarzom drzwiprzesuwne.pl", description: "Udostępniamy nowy kanał sprzedaży gotowych skrzydeł drzwiowych. Pod adresem drzwiprzesuwne.pl znajduje się program, w którym szybko i wygodnie można zaprojektować, wycenić i zamówić skrzydła drzwiowe." },
  { year: 2022, title: "Dwudziestolecie działalności firmy", description: "Dzięki zaufaniu klientów i owocnej współpracy mamy ogromną przyjemność świętować dwudziestolecie działalności. Z dumą patrzymy wstecz i pełni entuzjazmu kreujemy naszą przyszłość." },
  { year: 2024, title: "Zmiany w ofercie Systemu Kreator", description: "Wprowadzamy zmiany w profilach Systemu Kreator dodając do wybranych profilów komorę do montażu nóżek oraz modyfikując nóżki i wprowadzając inne akcesoria." },
]

export const HistoryComponent = () => {
  const [ selectedIndex, setSelectedIndex ] = useState<number>(0)

  return (
    <div className="w-full bg-white">
      <div className="mx-auto container">
        <div className="px-4 py-16">
          <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

          <h2 className="mb-16 w-full font-head font-normal text-5xl text-aluprofil-gray">
            Historia działalności
          </h2>

          <div className="flex flex-col gap-y-16 items-center">
            <ul className="w-full flex flex-row lg:justify-center overflow-auto">
              {items.map(
                  (item, index) => (
                    <li key={item.year} className="flex flex-col">
                      <a className="block px-3 text-aluprofil-gray/80" href="#"
                        onClick={
                          (e) => {
                            e.preventDefault()

                            setSelectedIndex(index)
                          }
                        }
                      >
                        {item.year}
                      </a>

                      <span className="w-full grid grid-cols-alt-3 items-center">
                        {index !== 0 ? (
                          <span className="col-start-1 block h-0.5 bg-aluprofil-gray" />
                        ) : null}

                        <span className="col-start-2 block">
                          <a className="flex size-4 items-center justify-center bg-aluprofil-gray/20 rounded-full" href="#"
                            onClick={
                              (e) => {
                                e.preventDefault()

                                setSelectedIndex(index)
                              }
                            }
                          >
                            <span
                              className={
                                clsx(
                                  "block size-3 rounded-full", index === selectedIndex ? "bg-aluprofil-red" : "bg-aluprofil-gray"
                                )
                              }
                            />
                          </a>
                        </span>

                        <span className="col-start-3 block h-0.5 bg-aluprofil-gray" />
                      </span>
                    </li>
                  )
                )
              }
            </ul>

            {items.map(
                (item, index) => index === selectedIndex ? (
                  <div key={index} className="flex flex-col gap-4 md:w-1/2">
                    <div className="font-head font-bold text-2xl text-aluprofil-gray">
                      {item.title}
                    </div>

                    <div className="text-base text-aluprofil-gray">
                      {item.description}
                    </div>
                  </div>
                ) : null
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

import { ReactNode } from "react"

const functions: {
  name: ReactNode
  desc: ReactNode
  icon: ReactNode
}[] = [
  { name: "Tor o długości do 6,00m", desc: "Dobierz odpowiednią długość toru dolnego, górnego i zaślepki z długości od 1,70m do 6,00m.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe013;</span>) },
  { name: "Profile w kolorystyce RAL", desc: "Dobierz kolor każdego profilu spośród prawie 200 dostępnych kolorów RAL.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00d;</span>) },
  { name: "Rączka z okleiną PCV", desc: "Wybierz rączkę Juno z możliwością montażu okleiny PCV w profilu.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00f;</span>) },
  { name: "Profile z folią drewnopodobną", desc: "Udekoruj profile wklejający folię drewnopodobną lub dekoracyjną.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00e;</span>) },
  { name: "Łączniki dla szkła do 8mm", desc: "Dobierz łącznik spośród 8 łączników dedykowanych szkłu w tym 4 dla szkła do 8mm.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00c;</span>) },
  { name: "Łączniki dla płyty do 18mm", desc: "Dobierz łącznik spośród 10 łączników dedykowanych płycie od 10mm do 18mm.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00b;</span>) },
  { name: "Głębokość systemu już od 56mm", desc: "Wybierz najpłytsze systemy z torem o głębokości jedynie 56mm.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe00a;</span>) },
  { name: "Samodomykacze skrajne", desc: "Dobierz samodomykacz lewy i prawy  do wagi drzwi od 25kg do 60kg.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe010;</span>) },
  { name: "Samodomykacz środkowy", desc: "Dodaj dodatkowy samodomykacz środkowy do zabudów od 3 drzwi przesuwnych.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe011;</span>) },
  { name: "Drzwi przesuwne do 3,50m wysokości", desc: "Zaprojektuj drzwi przesuwne o wysokości do 3,50m i głębokości systemu 82mm.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe009;</span>) },
  { name: "Tory dolne licowane z powierzchnią", desc: "Wybierz tor dolny do instalacji w płycie meblowej, wykładzinie, panelu czy terakocie.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe012;</span>) },
  { name: "Dożywotnia gwarancja na układy jezdne", desc: "Wybierz wózki z zamkniętym łożyskiem i zwiększoną trwałością.", icon: (<span className="block w-18 h-18 font-icon text-7xl/none text-aluprofil-gray">&#xe008;</span>) },
]

export const FunctionsComponent = () => {
  return (
    <div className="w-full bg-white">
      <div className="mx-auto container">
        <div className="px-4 py-16">
          <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

          <h2 className="mb-16 w-full font-head font-normal text-5xl text-aluprofil-gray">
            Funkcje
          </h2>

          <div className="grid grid-cols-1 flex-wrap gap-x-6 gap-y-16 md:grid-cols-2 lg:grid-cols-3">
            {functions.map(
                (func, index) => (
                  <div key={index} className="flex flex-col gap-4">
                    <div className="w-18 h-18">
                      {func.icon}
                    </div>

                    <div className="h-20 relative font-head font-bold text-2xl text-aluprofil-gray after:content-[''] after:absolute after:left-0 after:bottom-0 after:mt-4 after:w-12 after:h-0.25 after:block after:border-b after:border-b-aluprofil-red">
                      {func.name}
                    </div>

                    <div className="text-base text-aluprofil-gray">
                      {func.desc}
                    </div>
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

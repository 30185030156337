import clsx from "clsx"
import { useState, useEffect, ReactNode } from "react"
import { Helmet } from "react-helmet"

import ChevronLeft from "assets/Chevron-left.svg"
import ChevronRight from "assets/Chevron-right.svg"

const slides: {
  title: ReactNode
  description: ReactNode
}[] = [
  { title: "Ponad 20-letnie doświadczenie", description: "Nieustannie od 2002 roku dostarczamy stolarzom rozwiązania do produkcji zabudów meblowych. Oprócz systemów do drzwi przesuwnych, rozwieranych, składanych i przejściowych, systematycznie rozszerzamy naszą ofertę o elementy uzupełniające, które ułatwiają pracę z naszymi produktami począwszy od projektowania, poprzez montaż, a na użytkowaniu skończywszy." },
  { title: "Terminowy dostawca w kraju i na świecie", description: "Dzięki wysokim stanom magazynowym oraz cotygodniowym dostawom towarów w każdy rejon Polski jesteśmy w stanie zagwarantować naszym Odbiorcom terminowość dostaw, a w sytuacjach awaryjnych posiłkujemy się współpracą ze sprawdzonymi firmami kurierskimi." },
  { title: "Wysoka jakość produktów", description: "O wysoką jakość naszych produktów dba doświadczony dział konstrukcyjny. Każdy produkt wprowadzany przez nas na rynek przechodzi szereg testów zarówno jakościowych jak i wytrzymałościowych, a nad ich przebiegiem czuwa dział kontroli jakości." },
  { title: "Wsparcie regionalnych przedstawicieli handlowych", description: "Nasi przedstawiciele handlowi to nie tylko koordynatorzy sprzedaży, ale przede wszystkim doradcy techniczni. Podczas systematycznych wizyt u naszych Klientów zbierają oni informacje o potrzebach rynkowych, mające na celu udoskonalanie naszych produktów, jak również służą wiedzą techniczną dotyczącą naszych systemów." },
  { title: "Dożywotnia gwarancja na systemy jezdne", description: "Wszystkie nasze wózki posiadają łożyska zamknięte, co chroni je przed zanieczyszczeniami i zwiększa trwałość, zapewniając długie, ciche i płynne użytkowanie. Dlatego też jakość naszych systemów jezdnych potwierdzamy dożywotnią gwarancją." },
  { title: "Szkolenia teoretyczne i praktyczne", description: "Na bieżąco organizujemy w siedzibie naszej firmy szkolenia zarówno teoretyczne jak i praktyczne, podczas których Klienci nie tylko nabywają niezbędnych informacji o naszych produktach, ale również mają możliwość zweryfikowania nabytej wiedzy podczas samodzielnego składania skrzydła drzwiowego. Odbyte szkolenie potwierdzamy zawsze wręczeniem imiennych certyfikatów." },
  { title: "Materiały marketingowe", description: "Nasz dział marketingu dokłada wszelkich starań, by nasi Klienci docierali do jak największej liczby odbiorców poprzez udostępnianie materiałów drukowanych (takich jak foldery, instrukcje montażu, bannery czy plakaty reklamowe), różnego rodzaju ekspozytorów, makiet i wzorników, jak również wszelkich materiałów cyfrowych, które mogą zamieścić na swoich stronach internetowych czy postach w mediach społecznościowych." },
  { title: "Makiety i ekspozycje", description: "Naszym klientom oferujemy nie tylko  gotowe makiety ale również przygotowywane indywidualnie ekspozycje. Współpracę rozpoczynamy już na etapie ich projektowania, wsłuchujemy się w sugestie, dzięki czemu jesteśmy w stanie stworzyć ekspozytory dopasowane zarówno do potrzeb jak i możliwości działu sprzedaży." },
  { title: "Program Olimp", description: "W 2015 roku stworzyliśmy i udostępniliśmy naszym odbiorcom bezpłatnie program do projektowania drzwi Olimp. To intuicyjne narzędzie pozwala zaprojektować fasadę, by finalnie wygenerować listę profili i formatek potrzebnych do złożenia skrzydeł drzwiowych." },
  { title: "Wysokie stany magazynowe zapewniające płynność dostaw", description: "Wiemy jak ważne dla naszych Partnerów jest dotrzymywanie terminów, dlatego dokładamy wszelkich starań, by nasze stany magazynowe zawsze były na poziomie pozwalającym na utrzymanie płynności dostaw." },
  { title: "Pomoce techniczne i montażowe", description: "Jesteśmy uważni na potrzeby i sugestie naszych Klientów. Dlatego, starając się ułatwić im pracę z naszymi systemami, wprowadziliśmy do oferty szereg pomocy technicznych przydatnych zarówno na etapie produkcyjnym jak i podczas montażu u odbiorcy docelowego." },
  { title: "Szeroki asortyment regularnie uzupełniany o akcesoria meblowe", description: "Staramy się zaspokajać potrzeby naszego Klienta kompleksowo, dlatego też nasz asortyment stale poszerzamy o wszelakie akcesoria meblowe, podnoszące standard wyposażenia w szafach i garderobach. " },
  { title: "Ekspert w technologii drzwi przesuwnych", description: "Wieloletnie doświadczenie w branży oraz posiadanie własnej produkcji skrzydeł drzwiowych, która pozwala nam na ciągłe testowanie i usprawnianie systemów, skutkuje wiedzą przekładającą się na wysoką jakość produktów." },
  { title: "Szeroka sieć dystrybucji zarówno w kraju jak i zagranicą", description: "W trosce o jak najlepszą dostępność profili od lat budujemy i rozszerzamy bogatą już sieć dystrybutorów w kraju jak również licznych przedstawicieli poza jego granicami. " },
  { title: "Rozpoznawalna marka", description: "Od początku istnienia naszej firmy byliśmy obecni na wielu branżowych imprezach targowych. Do naszych Odbiorców docieramy również za pośrednictwem organizowanych u naszych Partnerów dni otwartych. Swoją obecność zaznaczamy również w artykułach prasowych oraz sponsorując różnego rodzaju gale, wybory Miss Polonia, motosporty czy sporty walki." },
  { title: "Prawa autorskie i patentowe", description: "Nasze produkty objęte są prawem patentowym na wzory użytkowe i przemysłowe. Dodatkowo, zgodność naszych wyrobów z obecnymi normami w dziedzinie bezpieczeństwa potwierdzamy atestem PZH." },
  { title: "Partnerstwo", description: "Dbałość o relacje z naszymi Partnerami owocuje wieloletnią współpracą, budowaną na wzajemnym zaufaniu i zrozumieniu." },
  { title: "Wysokie rabaty", description: "Naszym Odbiorcom oferujemy atrakcyjny system rabatowania, dopasowanym indywidualnie w oparciu o rodzaj i ilość zamawianego asortymentu." },
  { title: "Sprawna logistyka dostaw", description: "We własnym zakresie organizujemy cotygodniowe dostawy w każdym rejonie Polski, korzystając z floty przystosowanych pojazdów, dzięki którym unikamy ryzyka uszkodzeń podczas transportu. Jedynie w sprawach nagłych posiłkujemy się zaufanymi firmami kurierskimi." },
  { title: "Dział obsługi klienta", description: "Nasz Zespół odznacza się wysokim profesjonalizmem w podejściu do Klienta. Szybka i sprawna realizacja zamówień, doradztwo i wiedza techniczna, jak również ciepłe i partnerskie podejście do Klienta to jedynie niektóre cechy, które wyróżniają nas na tle konkurencji." },
  { title: "Akcje promocyjne", description: "Systematycznie organizujemy różnego rodzaju akcje promocyjne, mające na celu przybliżenie naszej oferty szerszemu gronu odbiorców." },
  { title: "Bogata gama kolorystyczna z dodatkową możliwością lakierowania", description: "Oprócz standardowo dostępnych w naszej ofercie kolorów: srebrny, j. brąz, j. brąz szczotkowany, biały połysk oraz czarny mat, naszym Klientom oferujemy możliwość polakierowania profili na jeden z prawie 200 kolorów RAL. Każdy z nich dostępny jest zarówno w macie jak i w połysku." },
  { title: "Dbałość o szczegóły", description: "Estetykę i dbałość o szczegóły od zawsze stawiamy na równi z funkcjonalnością i jakością, dlatego nieustannie wprowadzamy do naszej oferty detale dopełniające efekt końcowy. Przykładem mogą tu być wszelkiego rodzaju maskowania otworów i wkrętów, dyskretne stopery w torze górnym, zastosowanie czarnej uszczelki czy też dostosowanie profili do wklejenia folii dekoracyjnej." },
  { title: "Produkty dla każdego", description: "Dbamy o to by nasze systemy były dostępne dla jak największej liczby odbiorców, dlatego w naszej ofercie każdy znajdzie coś dla siebie, zarówno odbiorcy poszukujący niskobudżetowych rozwiązań, jak również poszukiwacze produktów ekskluzywnych." },
]

const Carousel = ({
    slides,
    interval
  } : {
    slides: {
      title: ReactNode
      description: ReactNode
    }[],
    interval: number
  }
) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const nextSlide = () => {
    setCurrentIndex(
      (prevIndex) => prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    )
  }

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    )
  }

  useEffect(
    () => {
      const slideInterval = setInterval(nextSlide, interval);

      return () => clearInterval(slideInterval);
    },
      [ currentIndex, interval ]
  );

  return (
    <>
      <div className="flex flex-row">
        <div className="shring place-content-center">
          <button className="focus:outline-none"
            onClick={prevSlide}
          >
            <img className="min-w-10 min-h-10 max-w-10 max-h-10" src={ChevronLeft} alt="Wstecz" />
          </button>
        </div>

        <div className="grow">
          <div className="w-full overflow-hidden grid grid-cols-1">
            {slides.map((slide, index) => (
              <div key={index}
                className={
                  clsx(
                    "w-full col-start-1 row-start-1 transition-transform duration-700 ease-in-out", index === currentIndex ? 'translate-x-0' : 'translate-x-full'
                  )
                }
                style={{
                    transform: `translateX(${(index - currentIndex) * 100}%)`
                  }
                }
              >
                <Slide title={slide.title} description={slide.description} />
              </div>
            ))}
          </div>
        </div>

        <div className="shring place-content-center">
          <button className="focus:outline-none"
            onClick={nextSlide}
          >
            <img className="min-w-10 min-h-10 max-w-10 max-h-10" src={ChevronRight} alt="Naprzód" />
          </button>
        </div>
      </div>

      <ul className="mt-12 hidden columns-2 columns-rule-2 columns-rule-solid columns-rule-aluprofil-light column-gap-12 lg:block">
        {slides.map(
            (slide, index) => (
              <li key={index}
                className={
                  clsx(
                    index === currentIndex ? "text-aluprofil-red" : "text-black/80", "cursor-pointer hover:text-aluprofil-red"
                  )
                }
                onClick={
                  () => setCurrentIndex(index)
                }
              >
                {slide.title}
              </li>
            )
          )
        }
      </ul>
    </>
  )
}

const Slide = ({
    title,
    description
  } : {
    title: ReactNode
    description: ReactNode
  }
) => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-col gap-4 md:w-1/2">
        <h3 className="font-head font-bold text-2xl text-aluprofil-gray">
          {title}
        </h3>

        <div className="text-base text-aluprofil-gray">
          {description}
        </div>
      </div>
    </div>
  )
}

export const AdventagesComponent = () => {
  const titles = slides.map(slide => slide.title).join(' | ')

  return (
    <>
      <Helmet>
        <title>Współpraca z ALUPROFIL</title>

        <meta
          property="og:type"
          content="website"
        />
        
        <meta
          property="og:title"
          content="Współpraca z ALUPROFIL"
        />

        <meta
          property="og:description"
          content={titles}
        />

        <meta
          name="description"
          content={titles}
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Współpraca z ALUPROFIL",
            "description": "${titles}"
          }`}
        </script>
      </Helmet>

      <div className="w-full bg-white">
        <div className="mx-auto container">
          <div className="px-4 py-16">
            <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

            <h2 className="mb-16 w-full font-head font-normal text-5xl text-aluprofil-gray">
              Atuty współpracy
            </h2>

            <Carousel interval={30 * 1000}
              slides={slides}
            />
          </div>
        </div>
      </div>
    </>
  )
}

import { forwardRef, InputHTMLAttributes, ReactNode } from "react";

export const CheckBox = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & {
    label: ReactNode
    errorMessage?: ReactNode
  }
>(
  (
    {
      label,
      errorMessage,
      ...props
    }, ref
  ) => {
    return (
      <div>
        <div className="flex flex-row gap-x-2">
          <div className="flex h-6 items-center">
            <input type={"checkbox"} className="h-4 w-4 rounded border-aluprofil-gray text-aluprofil-gray focus:ring-aluprofil-gray"
              ref={ref} {...props}
            />
          </div>

          <label className="font-medium text-sm/6 text-aluprofil-gray">
            {label}
          </label>
        </div>

        {errorMessage ? (
          <>
            <p className="mt-2 text-sm text-aluprofil-red">
              {errorMessage}
            </p>
          </>
        ) : null}
      </div>
    )
  }
)

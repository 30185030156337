import clsx from "clsx";
import { ReactNode, TextareaHTMLAttributes, forwardRef } from "react";

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label: ReactNode
    errorMessage?: ReactNode
  }
>(
  (
    {
      label,
      errorMessage,
      ...props
    }, ref
  ) => {
    return (
      <div>
        <label className="mb-2 block text-sm/none font-medium text-gray-900">
          {label}
        </label>

        <div>
          <textarea
            className={
              clsx(
                errorMessage ? "text-aluprofil-red ring-aluprofil-red placeholder:text-aluprofil-red focus:ring-aluprofil-red" : "text-aluprofil-gray ring-aluprofil-gray placeholder:text-aluprofil-gray focus:ring-aluprofil-red", "block w-full border-0 px-3 py-2 text-base rounded-[16px] ring-1 ring-inset focus:border-teal focus:outline-none focus:ring-aluprofil-red"
              )
            }
            ref={ref} {...props}
          />

          {errorMessage ? (
            <>
              <p className="mt-1 text-sm text-aluprofil-red">
                {errorMessage}
              </p>
            </>
          ) : null}
        </div>
      </div>
    )
  }
)

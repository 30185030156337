export const DownloadComponent = () => {
  return (
    <div className="w-full bg-aluprofil-gray">
      <div className="mx-auto container">
        <div className="px-4 py-16">
          <hr className="mb-16 border-t-2 border-white" />

          <h2 className="mb-16 w-full font-head font-normal text-5xl text-white">
            Materiały do pobrania
          </h2>

          <ul className="flex flex-row justify-center gap-6">
            <li className="block">
              <a href="/download/instrukcja_montazu-alu_24e-pl.pdf"
                className="flex flex-row items-center gap-4 font-head font-bold text-2xl text-white"
              >
                <span className="block w-18 h-18 font-icon font-normal text-7xl/none">&#xe016;</span>
                Instrukcja montażu
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export const categoriesSort: string[] = [
  "56de4421-0000-0000-999f-429028990c47", // MEDIUM 56 / AFRODYTA
  "4566abe4-0000-0000-885a-6a98b6e33e76", // MEDIUM 56 / CLASSIC PLUS
  "0bc39355-0000-0000-9885-883e735f6cc4", // MEDIUM 56 / KRONOS
  "32494847-0000-0000-9c05-e07d024d3f91", // MEDIUM 56 / URANOS
  "28853444-0000-0000-aabc-c1f2f6291bf8", // MEDIUM 56 / ZEUS
  "0082cf72-0000-0000-afcc-ab027933ea92", // PREMIUM 75 / CLASSIC
  "21ca6a62-0000-0000-b91a-e1f1449cfb42", // PREMIUM 75 / ERIS
  "6d2e86be-0000-0000-a1aa-da712b8649cb", // PREMIUM 75 / JUNO
  "11597024-0000-0000-be91-479613e1f50e", // PREMIUM 75 / JUNO RAMOWA
  "0823a212-0000-0000-b88f-775b13c77911", // PREMIUM 75 / MERKURY
  "7d7a4275-0000-0000-aa0b-834df4134416", // PREMIUM 75 / NEPTUN
  "5032154e-0000-0000-a87c-631937f59504", // PREMIUM 75 / ORION
  "7b531ebd-0000-0000-8a68-bc34d84b2632", // PREMIUM 75 / PLUTON
  "0bb45059-0000-0000-830f-cd64c6b9937d", // PREMIUM 75 / SYRIUSZ
  "76058e3e-0000-0000-9f78-74b8d8ce4d03", // PREMIUM 75 / URAN
  "744d4d27-0000-0000-8920-89671dc5756b", // PREMIUM 75 / WEGA
  "70292828-0000-0000-be5a-21faab10af9a", // PREMIUM 75 / WENUS
  "24ab8c10-0000-0000-a935-beffcdb9dc63", // PREMIUM 75 / WENUS EKO
  "38fb6d6c-0000-0000-ae83-f34e95f34804", // PREMIUM 75 / WESTA
  "0c834711-0000-0000-ae91-22bd20002bef", // PREMIUM 75 / WESTA RAMOWA
  "79ec2526-0000-0000-96f6-1c603779c32d", // PREMIUM 75 / WESTA BIS
  "6e92d83d-0000-0000-83cc-a97064a6cb36", // EXCLUSIVE 82 / ARES
  "03bcbe96-0000-0000-a877-4a794da685fd", // EXCLUSIVE 82 / HERMES
  "3f0713f0-0000-0000-b0fc-01dd94f1c6fa", // EXCLUSIVE 82 / POSEJDON
  "4fdb09fd-0000-0000-bf00-55e3da4563fb", // CYKLOP / ARES
  "023ed924-0000-0000-b90a-70a85ef84b25", // CYKLOP / HERMES
  "7702a270-0000-0000-9165-a0ad9530194c", // CYKLOP / SYRIUSZ
  "027ed7ce-0000-0000-86c5-3217fef42752", // CYKLOP / WEGA
  "1d9e45a9-0000-0000-ae34-e8f68b24de0f", // CYKLOP / WESTA
  "47f8af64-0000-0000-b297-a431a49abc77", // CYKLOP / WESTA RAMOWA
  "523c2329-0000-0000-9c3e-c691a55e7aeb", // CYKLOP / WESTA BIS
  "29a9ec78-0000-0000-8a32-111cc876c130", // TYTAN / ARES
  "31df109d-0000-0000-baf4-92cb7980f0fd", // TYTAN / HERMES
  "3344dfd4-0000-0000-8159-05be9368dc68", // TYTAN / KRONOS
  "4e2b7da7-0000-0000-b098-22abb42afb47", // TYTAN / NEPTUN
  "10c91129-0000-0000-8975-7d9b00987b02", // TYTAN / POSEJDON
  "49dd35b2-0000-0000-83f1-cebb49d264b0", // TYTAN / SYRIUSZ
  "74524731-0000-0000-aa83-45db4a6231c3", // TYTAN / URAN
  "1518e559-0000-0000-a376-6ec7fa733df7", // TYTAN / URANOS
  "306f09ba-0000-0000-aa03-69d28383c383", // TYTAN / WEGA
  "6b2bcf50-0000-0000-8d45-5a7e7fb343b3", // TYTAN / WESTA
  "1ba7d5a6-0000-0000-8670-ab231a400579", // TYTAN / WESTA RAMOWA
  "1257708d-0000-0000-8c59-1394ecf442b4", // SOLO / SOLO
]

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react"
import { doorTypesSort, kreatorTypesSort } from "data/typesSort"
import { Fragment, useEffect, useState } from "react"
import { getCategory, getTypes } from "services/api.product"
import { CategoryResponse, GroupResponse, TypeResponse } from "types"
import { Helmet } from "react-helmet"
import { groupsSort } from "data/groupsSort"

import Down from "assets/Chevron-down.svg"
import Up from "assets/Chevron-up.svg"

export const ProfileProductComponent = ({
    categoryId
  } : {
    categoryId: string
  }
) => {
  const [ category, setCategory ] = useState<{
      category: CategoryResponse
      _links: {
        [key: string]: string
      }
    } | null
  >(
    null
  )

  const [ types, setTypes ] = useState<{
      types: TypeResponse[]
    } | null
  >(null)

  useEffect(
    () => {
      getTypes()

      .then(
        (x) => setTypes(x)
      )
    },
      []
  )

  useEffect(
    () => {
      getCategory(categoryId)

      .then(
        (x) => setCategory(x)
      )
    },
      [ categoryId ]
  )

  if (
    ! category
  ) {
    return (
      <>
      </>
    )
  }

  const filteredgroups = category.category.categoryGroups.map(
    (x) => {
      const groupTypeId = x.groupProducts?.length ? x.groupProducts[0].productTypeId : -1

      return {
        ...x, groupTypeId
      }
    }
  )

  const filteredtypes = types?.types
  
  ?.filter(
    (x) => filteredgroups.some(y => y.groupTypeId === x.typeId)
  )

  if (
    ! filteredtypes
  ) {
    return (
      <>
      </>
    )
  }

  const doors = []

  if (category.category.attributes.isSlidingDoors) {
    doors.push("przesuwne")
  }

  if (category.category.attributes.isFoldingDoors){
    doors.push("składane")
  }

  if (category.category.attributes.isPullapartDoors) {
    doors.push("rozwierane")
  }

  if (category.category.attributes.isPathwayDoors) {
    doors.push("przejściowe")
  }

  const boards = []

  if (category.category.attributes.isBoard10mm) {
    boards.push("płyta 10 mm")
  }

  if (category.category.attributes.isBoard16mm) {
    boards.push("płyta 16 mm")
  }

  if (category.category.attributes.isBoard18mm) {
    boards.push("płyta 18 mm")
  }

  const glasses = []

  if (category.category.attributes.isGlass4plusBoard12mm) {
    glasses.push("szkło 4 mm + płyta 12 mm")
  }

  if (category.category.attributes.isGlass4mm) {
    glasses.push("szkło 4 mm")
  }

  if (category.category.attributes.isGlass4dot5mm) {
    glasses.push("szkło 4.5 mm")
  }

  if (category.category.attributes.isGlass6mm) {
    glasses.push("szkło 6 mm")
  }

  if (category.category.attributes.isGlass6dot4mm) {
    glasses.push("szkło 6.4 mm")
  }

  if (category.category.attributes.isGlass8mm) {
    glasses.push("szkło 8 mm")
  }

  const tracks = []

  if (category.category.attributes.isTrackLength1200) {
    tracks.push("1200")
  }

  if (category.category.attributes.isTrackLength1700) {
    tracks.push("1700")
  }

  if (category.category.attributes.isTrackLength1800) {
    tracks.push("1800")
  }

  if (category.category.attributes.isTrackLength2350) {
    tracks.push("2350")
  }

  if (category.category.attributes.isTrackLength3000) {
    tracks.push("3000")
  }

  if (category.category.attributes.isTrackLength4050) {
    tracks.push("4050")
  }

  if (category.category.attributes.isTrackLength6000) {
    tracks.push("6000")
  }

  const handles = []

  if (category.category.attributes.isHandleLength2500) {
    handles.push("2500")
  }

  if (category.category.attributes.isHandleLength2700) {
    handles.push("2700")
  }

  if (category.category.attributes.isHandleLength3000) {
    handles.push("3000")
  }

  if (category.category.attributes.isHandleLength3500) {
    handles.push("3500")
  }

  const maxWeights = []

  if (category.category.attributes.isDoorMaxWeight40kg) {
    maxWeights.push("40 kg")
  }

  if (category.category.attributes.isDoorMaxWeight50kg) {
    maxWeights.push("50 kg")
  }

  if (category.category.attributes.isDoorMaxWeight100kg) {
    maxWeights.push("100 kg")
  }

  const maxWidths = []

  if (category.category.attributes.isDoorMaxWidth500mm) {
    maxWidths.push("500 mm")
  }

  if (category.category.attributes.isDoorMaxWidth600mm) {
    maxWidths.push("600 mm")
  }

  let description = "";

  if (
       category.category.attributes.isSlidingDoors
    || category.category.attributes.isFoldingDoors
    || category.category.attributes.isPullapartDoors
    || category.category.attributes.isPathwayDoors
  ) {
    description = `Dostępne warianty drzwi: ${doors.join(", ")}.`
  }
  else {
    description = `Dostępne produkty: ${category.category.categoryGroups.map(x => x.groupName).join(", ")}.`
  }

  return (
    <>
      <Helmet>
        <title>{category.category.categoryFullName} w ALUPROFIL</title>

        <meta
          property="og:type"
          content="website"
        />

        <meta
          property="og:title"
          content={`${category.category.categoryFullName} w ALUPROFIL`}
        />

        <meta
          property="og:description"
          content={description}
        />

        <meta
          name="description"
          content={description}
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "${category.category.categoryFullName} w ALUPROFIL",
            "description": "${description}",
          }`}
        </script>
      </Helmet>

      <div className="w-full bg-aluprofil-light">
        <div className="mx-auto container">
          <div className="px-4 py-16">
            <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

            <div className="flex flex-col gap-16">
              <div className="space-y-4">
                <h2 className="font-head font-normal text-5xl text-aluprofil-gray">
                  {category.category.categoryName}
                </h2>

                {category._links[`categoryGet.${category.category.categoryId}.assemblyAssetGet`] ? (
                  <div className="flex flex-row">
                    <a className="-mx-3 w-auto block px-3 text-aluprofil-red rounded-full ring-1 ring-inset ring-aluprofil-red"
                      href={category._links[`categoryGet.${category.category.categoryId}.assemblyAssetGet`]}
                    >
                      Pobierz instrukcje montażu
                    </a>
                  </div>
                ) : null}
              </div>

              {(
                   category.category.attributes.isSlidingDoors
                || category.category.attributes.isFoldingDoors
                || category.category.attributes.isPullapartDoors
                || category.category.attributes.isPathwayDoors
              ) ? (
                <div className="flex flex-col gap-6 divide-y-2 divide-aluprofil-gray/20 lg:flex-row lg:divide-x-2 lg:divide-y-0">
                  <div className="w-full lg:w-1/3 lg:py-2">
                    <div className="flex flex-col gap-6">
                      <div>
                        <h3 className="mb-2 w-full block font-medium text-lg text-aluprofil-gray">
                          Drzwi dostępne w wariantach:
                        </h3>

                        <ul className="flex flex-row flex-wrap">
                          {category.category.attributes.isSlidingDoors ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 font-icon text-5xl/none text-aluprofil-gray">
                                &#xe001;
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                przesuwne
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isFoldingDoors ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 font-icon text-5xl/none text-aluprofil-gray">
                                &#xe003;
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                składane
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isPullapartDoors ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 font-icon text-5xl/none text-aluprofil-gray">
                                &#xe002;
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                rozwierane
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isPathwayDoors ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 font-icon text-5xl/none text-aluprofil-gray">
                                &#xe000;
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                przejściowe
                              </span>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="w-full pt-6 lg:pl-6 lg:w-1/3 lg:py-2">
                    <div className="flex flex-col gap-6">
                      {boards.length || glasses.length ? (
                        <div className="flex flex-col gap-2">
                          <h3 className="w-full block font-medium text-lg text-aluprofil-gray">
                            Wypełnienie:
                          </h3>

                          {boards.length ? (
                            <ul className="-mx-2 flex flex-row flex-wrap divide-x divide-aluprofil-gray">
                              {boards.map(
                                (x) => (
                                    <li key={x} className="block px-2 text-sm text-aluprofil-gray">
                                      {x}
                                    </li>
                                  )
                                )
                              }
                            </ul>
                          ) : null}

                          {glasses.length ? (
                            <ul className="-mx-2 flex flex-row flex-wrap divide-x divide-aluprofil-gray">
                              {glasses.map(
                                (x) => (
                                    <li key={x} className="block px-2 text-sm text-aluprofil-gray">
                                      {x}
                                    </li>
                                  )
                                )
                              }
                            </ul>
                          ) : null}
                        </div>
                      ) : null}

                      {tracks.length ? (
                        <div>
                          <h3 className="mb-2 w-full block font-medium text-lg text-aluprofil-gray">
                            Długości torów (mm):
                          </h3>

                          <ul className="-mx-2 flex flex-row flex-wrap divide-x divide-aluprofil-gray">
                            {tracks.map(
                              (x) => (
                                  <li key={x} className="block px-2 text-sm text-aluprofil-gray">
                                    {x}
                                  </li>
                                )
                              )
                            }
                          </ul>
                        </div>
                      ) : null}

                      {handles.length ? (
                        <div>
                          <h3 className="mb-2 w-full block font-medium text-lg text-aluprofil-gray">
                            Długości rączek (mm):
                          </h3>

                          <ul className="-mx-2 flex flex-row flex-wrap divide-x divide-aluprofil-gray">
                            {handles.map(
                              (x) => (
                                  <li key={x} className="block px-2 text-sm text-aluprofil-gray">
                                    {x}
                                  </li>
                                )
                              )
                            }
                          </ul>
                        </div>
                      ) : null}

                      {maxWeights.length ? (
                        <div>
                          <h3 className="mb-2 w-full block font-medium text-lg text-aluprofil-gray">
                            Maksymalna waga skrzydła:
                          </h3>

                          <ul className="-mx-2 flex flex-row flex-wrap divide-x divide-aluprofil-gray">
                            {maxWeights.map(
                              (x) => (
                                  <li key={x} className="block px-2 text-sm text-aluprofil-gray">
                                    {x}
                                  </li>
                                )
                              )
                            }
                          </ul>
                        </div>
                      ) : null}

                      {maxWidths.length ? (
                        <div>
                          <h3 className="mb-2 w-full block font-medium text-lg text-aluprofil-gray">
                            Maksymalna szerokość skrzydła:
                          </h3>

                          <ul className="-mx-2 flex flex-row flex-wrap divide-x divide-aluprofil-gray">
                            {maxWidths.map(
                              (x) => (
                                  <li key={x} className="block px-2 text-sm text-aluprofil-gray">
                                    {x}
                                  </li>
                                )
                              )
                            }
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="w-full pt-6 lg:pl-6 lg:w-1/3 lg:py-2">
                    <figure className="flex flex-col gap-6">
                      {category._links[`categoryGet.${category.category.categoryId}.imageAssetGet`] ? (
                        <img className="w-full"
                          src={category._links[`categoryGet.${category.category.categoryId}.imageAssetGet`]}
                        />
                      ) : null}

                      <figcaption>
                        <h3 className="mb-2 w-full block font-medium text-lg text-aluprofil-gray">
                          Kolorystyka:
                        </h3>

                        <ul className="flex flex-row flex-wrap gap-2">
                          {category.category.attributes.isRaw ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 rounded-full bg-[#c3c3c3] border-2 border-aluprofil-gray">
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                surowy
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isSilver ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 rounded-full bg-[#c3c3c3] border-2 border-aluprofil-gray">
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                srebrny
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isBrozne ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 rounded-full bg-[#b97a57] border-2 border-aluprofil-gray">
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                j. brąz
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isBrozneBrushed ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 rounded-full bg-[#b97a57] border-2 border-aluprofil-gray">
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                j. brąz szczotkowany
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isGold ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 rounded-full bg-[#ffc90e] border-2 border-aluprofil-gray">
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                złoty
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isWhiteGloss ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 rounded-full bg-[#ffffff] border-2 border-aluprofil-gray">
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                biały połysk
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isBlackMat ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 block w-12 h-12 rounded-full bg-[#000000] border-2 border-aluprofil-gray">
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                czarny mat
                              </span>
                            </li>
                          ) : null}

                          {category.category.attributes.isRal ? (
                            <li className="w-20 flex flex-col items-center">
                              <span className="mb-2 grid grid-cols-3 grid-rows-3 w-12 h-12 overflow-hidden rounded-full border-2 border-aluprofil-gray">
                                <span className="block bg-[#D6AE01]" />
                                <span className="block bg-[#F7BA0B]" />
                                <span className="block bg-[#EFB700]" />
                                <span className="block bg-[#D84B20]" />
                                <span className="block bg-[#FA842B]" />
                                <span className="block bg-[#E75B12]" />
                                <span className="block bg-[#EC7C25]" />
                                <span className="block bg-[#DB6A50]" />
                                <span className="block bg-[#C35831]" />
                              </span>

                              <span className="w-full block text-sm text-center text-aluprofil-gray">
                                RAL
                              </span>
                            </li>
                          ) : null}
                        </ul>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="flex flex-row gap-6">
              <div className="relative hidden lg:block lg:w-1/3">
                <ul className="sticky top-0 py-16 block space-y-2">
                  {filteredtypes

                  .sort(
                    (category.category.attributes.isSlidingDoors || category.category.attributes.isFoldingDoors || category.category.attributes.isPullapartDoors || category.category.attributes.isPathwayDoors) ? doorTypesSorter : (category.category.attributes.isKreator) ? kreatorTypesSorter : nameTypesSorter
                  )
                  
                  .map(
                      (x) => (
                        <li key={x.typeId}>
                          <a className="w-auto block text-sm text-aluprofil-gray hover:text-aluprofil-red"
                            href={`#${x.typeId}`}
                          >
                            {x.typeName}
                          </a>
                        </li>
                      )
                    )
                  }
                </ul>
              </div>

              <div className="w-full py-16 flex flex-col gap-6 lg:w-2/3">
                {filteredtypes

                .sort(
                  (category.category.attributes.isSlidingDoors || category.category.attributes.isFoldingDoors || category.category.attributes.isPullapartDoors || category.category.attributes.isPathwayDoors) ? doorTypesSorter : (category.category.attributes.isKreator) ? kreatorTypesSorter : nameTypesSorter
                )
                
                .map(
                    (x) => (
                      <div key={x.typeId} id={`${x.typeId}`}>
                        <Disclosure defaultOpen={true}>
                          {({ open }) => (
                              <>
                                <DisclosureButton className="w-full flex flex-row justify-between px-3 py-2 bg-aluprofil-gray/60">
                                  <span className="font-medium text-left text-white uppercase">
                                    {x.typeName}
                                  </span>

                                  <span className="w-6 h-6 block place-items-center text-aluprofil-red bg-white rounded-full">
                                    {open ? (
                                        <>
                                          <img className="min-w-6 min-h-6 max-w-6 max-h-6" src={Up} alt="Zamknij" />
                                        </>
                                      ) : (
                                        <>
                                          <img className="min-w-6 min-h-6 max-w-6 max-h-6" src={Down} alt="Otwórz" />
                                        </>
                                      )
                                    }
                                  </span>
                                </DisclosureButton>
        
                                <DisclosurePanel>
                                  <div className="w-full p-4 bg-white/60 border-b-2 border-b-aluprofil-gray/20">
                                    <table className="w-full divide-y-2 divide-aluprofil-gray/20">
                                      {filteredgroups
                                      
                                      .filter(
                                        (y) => y.groupTypeId === x.typeId
                                      )

                                      .sort(
                                        groupsSorter
                                      )
        
                                      .map(
                                        (y) => {
                                          const firstProduct = y.groupProducts.filter(
                                            (z) => z.productImageAsset
                                          )
        
                                          return (
                                            <Fragment key={y.groupId}>
                                              {y.groupProducts.map(
                                                  (z, i) => (
                                                    <tr key={z.productId}>
                                                      {i === 0 ? (
                                                          <td rowSpan={y.groupProducts.length} className="hidden lg:table-cell lg:w-48 p-2">
                                                            {firstProduct.length ? (
                                                              <>
                                                                {category._links[`productGet.${firstProduct[0].productId}.imageAssetGet`] ? (
                                                                  <img className="w-full" src={category._links[`productGet.${firstProduct[0].productId}.imageAssetGet`]} />
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                          </td>
                                                        ) : null
                                                      }
        
                                                      <td className="w-20 p-2 text-center text-aluprofil-gray">
                                                        {z.productCode}
                                                      </td>
        
                                                      <td className="p-2 text-aluprofil-gray">
                                                        {z.productName}
                                                      </td>
                                                    </tr>
                                                  )
                                                )
                                              }
                                            </Fragment>
                                          )
                                        }
                                      )}
                                    </table>
                                  </div>
                                </DisclosurePanel>
                              </>
                            )
                          }
                        </Disclosure>
                      </div>
                    )
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const nameTypesSorter = (
  a: TypeResponse, b: TypeResponse
) => {
  return a.typeName.localeCompare(b.typeName)
}

const doorTypesSorter = (
  a: TypeResponse, b: TypeResponse
) => {
  let indexA = doorTypesSort.indexOf(a.typeId)
  let indexB = doorTypesSort.indexOf(b.typeId)

  if (indexA === -1) indexA = 2147483647
  if (indexB === -1) indexB = 2147483647

  let test = indexA - indexB

  if (test === 0) {
    test = nameTypesSorter(a, b)
  }
  
  return test
}

const kreatorTypesSorter = (
  a: TypeResponse, b: TypeResponse
) => {
  let indexA = kreatorTypesSort.indexOf(a.typeId)
  let indexB = kreatorTypesSort.indexOf(b.typeId)

  if (indexA === -1) indexA = 2147483647
  if (indexB === -1) indexB = 2147483647

  let test = indexA - indexB

  if (test === 0) {
    test = nameTypesSorter(a, b)
  }
  
  return test
}

const nameGroupsSorter = (
  a: GroupResponse, b: GroupResponse
) => {
  return a.groupName.localeCompare(b.groupName)
}

const groupsSorter = (
  a: GroupResponse, b: GroupResponse
) => {
  let indexA = groupsSort.indexOf(a.groupId)
  let indexB = groupsSort.indexOf(b.groupId)

  if (indexA === -1) indexA = 2147483647
  if (indexB === -1) indexB = 2147483647

  let test = indexA - indexB

  if (test === 0) {
    test = nameGroupsSorter(a, b)
  }
  
  return test
}

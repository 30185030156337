import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import { sitemap } from "data/sitemap";
import { selectFlatten } from "array/ArrayExtensions";
import { ProfilesProductPage } from "pages/Product/ProfilesProduct.page";
import { FeaturesPage } from "pages/Features/Features.page";
import { AboutUsPage } from "pages/AboutUs/AboutUs.page";
import { ProfileProductPage } from "pages/Product/ProfileProduct.page";
import { OtherProductPage } from "pages/Product/OtherProduct.page";
import { PolicyPage } from "pages/Policy/Policy.page";
import { GroupedOtherProductPage } from "pages/Product/GroupedOtherProduct.page";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const menuitems = selectFlatten(
  sitemap, (x) => x.children ?? []
)

const Page = () => {
  const location = useLocation();

  useLayoutEffect(
    () => {
      window.scrollTo({
          top: 0
        }
      );
    },
      [ location.pathname ]
  );

  return (
    <Routes>
      {menuitems

      .filter(
        (x) => x.link !== undefined
      )
      
      .map(
          (x) => {
            if (
              x.args
            ) {
              if (
                "door" in x.args
              ) {
                switch (
                  x.args["door"]
                ) {
                  case "index": {
                    return (
                      <Route key={x.link} path={x.link}
                        element={<ProfilesProductPage />}
                      />
                    )
                  }

                  default: {
                    return (
                      <Route key={x.link} path={x.link}
                        element={<ProfileProductPage categoryId={x.args.door} />}
                      />
                    )
                  }
                }
              }

              if (
                "kreator" in x.args
              ) {
                return (
                  <Route key={x.link} path={x.link}
                    element={<ProfileProductPage categoryId={x.args.kreator} />}
                  />
                )
              }

              if (
                "accessories" in x.args
              ) {
                return (
                  <Route key={x.link} path={x.link}
                    element={<OtherProductPage categoryId={x.args.accessories} />}
                  />
                )
              }

              if (
                "connector" in x.args
              ) {
                return (
                  <Route key={x.link} path={x.link}
                    element={<GroupedOtherProductPage categoryId={x.args.connector} />}
                  />
                )
              }

              if (
                "decors" in x.args
              ) {
                return (
                  <Route key={x.link} path={x.link}
                    element={<ProfileProductPage categoryId={x.args.decors} />}
                  />
                )
              }

              if (
                "features" in x.args
              ) {
                return (
                  <Route key={x.link} path={x.link}
                    element={<FeaturesPage />}
                  />
                )
              }

              if (
                "contact" in x.args
              ) {
                return (
                  <Route key={x.link} path={x.link}
                    element={<AboutUsPage />}
                  />
                )
              }

              if (
                "policy" in x.args
              ) {
                return (
                  <Route key={x.link} path={x.link}
                    element={<PolicyPage />}
                  />
                )
              }
            }

            return null
          }
        )
      }
    </Routes>
  )
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Page />
    </BrowserRouter>
  </React.StrictMode>
)

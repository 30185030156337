import { useEffect, useState } from "react"
import { getCategory } from "services/api.product"
import { CategoryResponse } from "types"
import { Helmet } from "react-helmet"
import { productsSort } from "data/productsSort"

export const GroupedOtherProductComponent = ({
    categoryId
  } : {
    categoryId: string
  }
) => {
  const [ category, setCategory ] = useState<{
      category: CategoryResponse
      _links: {
        [key: string]: string
      }
    } | null
  >(
    null
  )

  useEffect(
    () => {
      getCategory(categoryId)

      .then(
        (x) => setCategory(x)
      )
    },
      [ categoryId ]
  )

  if (
    ! category
  ) {
    return (
      <>
      </>
    )
  }

  const description = `Dostępne produkty: ${category.category.categoryGroups.map(x => x.groupName).join(", ")}.`

  return (
    <>
      <Helmet>
        <title>{category.category.categoryName} w ALUPROFIL</title>

        <meta
          property="og:type"
          content="website"
        />

        <meta
          property="og:title"
          content={`${category.category.categoryFullName} w ALUPROFIL`}
        />

        <meta
          property="og:description"
          content={description}
        />

        <meta
          name="description"
          content={description}
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "${category.category.categoryFullName} w ALUPROFIL",
            "description": "${description}",
          }`}
        </script>
      </Helmet>

      <div className="w-full bg-aluprofil-light">
        <div className="mx-auto container">
          <div className="px-4 py-16">
            <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

            <div className="flex flex-col gap-16">
              <div className="space-y-4">
                <h2 className="font-head font-normal text-5xl text-aluprofil-gray">
                  {category.category.categoryName}
                </h2>

                {category._links[`categoryGet.${category.category.categoryId}.assemblyAssetGet`] ? (
                  <div className="flex flex-row">
                    <a className="-mx-3 w-auto block px-3 text-aluprofil-red rounded-full ring-1 ring-inset ring-aluprofil-red"
                      href={category._links[`categoryGet.${category.category.categoryId}.assemblyAssetGet`]}
                    >
                      Pobierz instrukcje montażu
                    </a>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-6">
                {category.category.categoryGroups.map(
                  (x) => (
                    <>
                      <span className="w-full flex flex-row justify-between px-3 py-2 font-medium text-left text-white uppercase bg-aluprofil-gray/60">
                        {x.groupName}
                      </span>

                      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
                        {x.groupProducts
                        
                        .sort(
                          (a, b) => {
                            const indexA = productsSort.indexOf(a.productId)
                            const indexB = productsSort.indexOf(b.productId)

                            return indexA - indexB
                          }
                        )

                        .map(
                            (y) => (
                              <li key={y.productId}>
                                <div className="flex flex-col justify-end p-6 bg-white/60 border-b-2 border-b-aluprofil-gray/20">
                                  {category._links[`productGet.${y.productId}.imageAssetGet`] ? (
                                    <img className="mb-4 w-full" src={category._links[`productGet.${y.productId}.imageAssetGet`]} />
                                  ) : null}

                                  <span className="-mx-6 h-15 block font-medium text-sm text-center text-aluprofil-gray">
                                    {y.productCode} | {y.productName}
                                  </span>
                                </div>
                              </li>
                            )
                          )
                        }
                      </ul>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

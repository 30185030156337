import axios from "axios";
import { ErrorResponse } from "types";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API,

    validateStatus: (status) => {
      return (status >= 200 && status < 400) || status === 404
    }
  }
)

export const resolveMessage = (
  x: ErrorResponse[]
) => {
  return x.map(y => y.message).join(" ")
}

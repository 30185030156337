import { LayoutPage } from "pages/_Layout/Layout.page"
import { BlankComponent } from "sections/Blank/Blank.component"
import { FooterComponent } from "sections/Footer/Footer.component"
import { NavbarComponent } from "sections/Navbar/Navbar.component"
import { PolicyComponent } from "sections/Policy/Policy.component"

export const PolicyPage = () => {
  return (
    <LayoutPage>
      <>
        <NavbarComponent />

        <PolicyComponent />

        <BlankComponent size="1/3" />

        <FooterComponent />
      </>
    </LayoutPage>
  )
}

import { LayoutPage } from "pages/_Layout/Layout.page"
import { BlankComponent } from "sections/Blank/Blank.component"
import { ContactComponent } from "sections/Contact/Contact.component"
import { FooterComponent } from "sections/Footer/Footer.component"
import { HistoryComponent } from "sections/History/History.component"
import { NavbarComponent } from "sections/Navbar/Navbar.component"

export const AboutUsPage = () => {
  return (
    <LayoutPage>
      <>
        <NavbarComponent />

        <HistoryComponent />

        <BlankComponent size="1/3" />

        <ContactComponent />

        <BlankComponent size="1/3" />

        <FooterComponent />
      </>
    </LayoutPage>
  )
}

import { LayoutPage } from "pages/_Layout/Layout.page"
import { BlankComponent } from "sections/Blank/Blank.component"
import { DownloadComponent } from "sections/Download/Download.component"
import { FooterComponent } from "sections/Footer/Footer.component"
import { FunctionsComponent } from "sections/Functions/Functions.component"
import { NavbarComponent } from "sections/Navbar/Navbar.component"
import { ProfileProductComponent } from "sections/Product/ProfileProduct.component"

export const ProfileProductPage = ({
    categoryId
  } : {
    categoryId: string
  }
) => {
  return (
    <LayoutPage>
      <>
        <NavbarComponent />

        <ProfileProductComponent categoryId={categoryId} />

        <DownloadComponent />

        <BlankComponent size="1/3" />

        <FunctionsComponent />

        <BlankComponent size="1/3" />

        <FooterComponent />
      </>
    </LayoutPage>
  )
}
